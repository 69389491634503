const i18nextKeys = {
  enabled: 'enabled',
  disabled: 'disabled',
  alwaysEnabled: 'alwaysEnabled',

  commonYes: 'common.yes',
  commonNo: 'common.no',
  commonShowMore: 'common.showMore',
  commonShowLess: 'common.showLess',
  commonReadMore: 'common.readMore',
  commonReadLess: 'common.readLess',
  commonProceed: 'common.proceed',
  commonClearSelection: 'common.clearSelection',
  commonTotal: 'common.total',
  commonMax: 'common.max',
  commonDate: 'common.date',
  commonReferenceCode: 'common.referenceCode',
  commonOrderId: 'common.orderId',
  commonCurrency: "common.currency",
  commonCryptocurrency: 'common.cryptocurrency',
  commonCryptocurrencies: 'common.cryptocurrencies',
  commonCreditCard: 'common.creditCard',
  commonAdditionalData: 'common.additionalData',
  commonSparks: 'common.sparks',
  commonUnits: 'common.units',
  commonEnvironment: "common.environment",
  commonSandbox: "common.sandbox",
  commonProduction: "common.production",
  commonVersion: "common.version",
  commonTemplate: "common.template",
  commonTerms: "common.terms",

  commonInfoUser: "common.info.user",
  commonInfoPersonal: 'common.info.personal',
  commonFirstName: "common.firstName",
  commonLastName: "common.lastName",
  commonBirthDate: "common.birthDate",
  commonPhoneNumber: "common.phoneNumber",
  commonEmail: "common.email",
  commonNationality: "common.nationality",

  commonAddressPersonal: 'common.address.personal',
  commonAddressShipping: 'common.address.shipping',
  commonAddressInvoice: 'common.address.invoice',
  commonAddressStreet: "common.address.street",
  commonAddressZip: "common.address.zip",
  commonAddressCity: "common.address.city",
  commonAddressRegion: "common.address.region",
  commonAddressCountry: "common.address.country",
  
  commonBankTransfer: 'common.bank.transfer',
  commonBankAccount: 'common.bank.account',
  commonBankAccountDetails: "common.bank.accountDetails",
  commonBankAddress: "common.bank.address",
  commonBankName: "common.bank.name",
  commonBankIban: "common.bank.iban",
  commonBankSwift: "common.bank.swift",
  commonBankBic: "common.bank.bic",
  commonBankBeneficiaryAccount: 'common.bank.beneficiary.account',
  commonBankBeneficiaryAddress: "common.bank.beneficiary.address",
  commonBankBeneficiaryName: "common.bank.beneficiary.name",
  commonBankBeneficiarySwift: "common.bank.beneficiary.swift",
  commonBankBeneficiaryBic: "common.bank.beneficiary.bic",
  commonBankAdditionalDetails: "common.bank.additionalDetails",
  commonBankAdditionalDetailsPlaceholder: "common.bank.additionalDetails.placeholder",
  commonBankIntermediaryAdd: "common.bank.intermediary.add",
  commonBankIntermediaryDelete: "common.bank.intermediary.delete",
  commonBankIntermediaryDetails: 'common.bank.intermediary.details',
  commonBankIntermediaryAccount: "common.bank.intermediary.account",
  commonBankIntermediaryAccountDetails: "common.bank.intermediary.accountDetails",
  commonBankIntermediaryAddress: "common.bank.intermediary.address",
  commonBankIntermediaryName: "common.bank.intermediary.name",
  commonBankIntermediaryIban: "common.bank.intermediary.iban",
  commonBankIntermediarySwift: "common.bank.intermediary.swift",

  commonWalletDetails: "common.wallet.details",
  commonWalletCrypto: 'common.wallet.crypto',
  commonWalletName: 'common.wallet.name',
  commonWalletAddress: 'common.wallet.address',
  commonWalletEthereumAddress: 'common.wallet.ethereumAddress',
  commonWalletSave: "common.wallet.save",

  commonLanguageDefault: "common.language.default",

  commonFee: "common.fee",
  commonFeeMethod: "common.fee.method",
  commonFeeCountry: "common.fee.country",
  commonFeeFixed: "common.fee.fixed",
  commonFeeVariable: "common.fee.variable",
  commonFeeTotal: "common.fee.total",
  commonFeePercentage: "common.fee.percentage",

  commonConfigTooltip: "common.config.tooltip",

  commonKycTier: 'common.kyc.tier',

  commonErrorInvalidQuantity: 'common.error.invalidQuantity',
  commonErrorNoData: 'common.error.noData',

  commonSearchCodeUser: "common.search.codeUser",
  commonSearchEmailUserId: "common.search.emailUserId",

  uploadFile: "upload.file",

  accountHeader: 'account.header',
  accountGetLanguageError: 'account.getLanguageError',
  accountSetLanguageError: 'account.setLanguageError',

  accountBankAccountsHeading: 'account.bankAccounts.heading',
  accountBankAccountsDeletionHeading: 'account.bankAccounts.deletion.heading',
  accountBankAccountsDeletionText: 'account.bankAccounts.deletion.text',

  accountWalletsHeading: 'account.wallets.heading',
  accountWalletsDeletionHeading: 'account.wallets.deletion.heading',
  accountWalletsDeletionText: 'account.wallets.deletion.text',

  buttonOk: 'button.ok',
  buttonBack: 'button.back',
  buttonLogout: 'button.logout',
  buttonHome: 'button.home',
  buttonConfirm: 'button.confirm',
  buttonConfirmAndSubmit: 'button.confirmAndSubmit',
  buttonCancel: 'button.cancel',
  buttonRemove: 'button.remove',
  buttonReject: 'button.reject',
  buttonDelete: 'button.delete',
  buttonDecline: 'button.decline',
  buttonAccept: 'button.accept',
  buttonApprove: 'button.approve',
  buttonSave: 'button.save',
  buttonSaveChanges: 'button.saveChanges',
  buttonManageCredentials: 'button.manageCredentials',
  buttonOrderHistory: 'button.orderHistory',
  buttonRedemptionHistory: 'button.redemptionHistory',
  buttonStartKyc: 'button.startKyc',
  buttonUpgradeKyc: 'button.upgradeKyc',
  buttonAddWallet: 'button.addWallet',
  buttonPassportScan: 'button.passportScan',
  buttonSelfie: 'button.selfie',
  buttonUtilityBill: 'button.utilityBill',
  buttonSubmit: 'button.submit',
  buttonValidate: 'button.validate',
  buttonCloseThisCase: 'button.closeThisCase',
  buttonReopenThisCase: 'button.reopenThisCase',
  buttonExport: 'button.export',
  buttonEdit: 'button.edit',
  buttonSend: 'button.send',
  buttonResend: 'button.resend',
  buttonReplace: 'button.replace',
  buttonCheckout: 'button.checkout',
  buttonContinue: 'button.continue',
  buttonConfirmAndContinue: 'button.confirmAndContinue',
  buttonSelectThisMethod: 'button.selectThisMethod',
  buttonSelectAndContinue: 'button.selectAndContinue',
  buttonClose: 'button.close',
  buttonMoreDetails: 'button.moreDetails',
  buttonAddToCart: 'button.addToCart',
  buttonLoginToBuy: 'button.loginToBuy',
  buttonMoreInfo: 'button.moreInfo',
  buttonAbort: 'button.abort',
  buttonSkip: 'button.skip',
  buttonAdd: 'button.add',
  buttonPromoteToAdmin: 'button.promoteToAdmin',
  buttonTurnOff: 'button.turnOff',
  buttonTurnOn: 'button.turnOn',
  buttonNotAvailable: 'button.notAvailable',

  inputNoResultsFound: "input.noResultsFound",

  cardsUserEditPersonalInfo: 'cards.user.editPersonalInfo',
  cardsBankAccountsNoBankAccountStored:
    'cards.bankAccounts.noBankAccountStored',
  cardsBankAccountsFormHeaderAdd: 'cards.bankAccounts.form.headerAdd',
  cardsBankAccountsFormHeaderEdit: 'cards.bankAccounts.form.headerEdit',
  cardsBankAccountsFormAddBankAccount: 'cards.bankAccounts.form.addBankAccount',
  cardsKycDetailsFormAddressLine: 'cards.kycDetails.form.addressLine',
  cardsKycDetailsFormState: 'cards.kycDetails.form.state',
  cardsKycCurrentTierHeader: 'cards.kyc.currentTier.header',
  cardsKycCurrentTierDescription: 'cards.kyc.currentTier.description',
  cardsKycLastCheckHeader: 'cards.kyc.lastCheck.header',
  cardsKycLastCheckDescription: 'cards.kyc.lastCheck.description',
  cardsKycDeclinedReason: 'cards.kyc.declined.reason',
  cardsKycDeclinedOpenReasonModal: 'cards.kyc.declined.openReasonModal',
  cardsKycDeclinedReasonModalHeader: 'cards.kyc.declined.reasonModalHeader',
  cardsWalletTypeNoWalletsStored: 'cards.walletType.noWalletsStored',
  cardsWalletType: 'cards.wallet.type',
  cardsWalletFormHeaderAdd: 'cards.wallet.form.headerAdd',
  cardsWalletFormHeaderEdit: 'cards.wallet.form.headerEdit',

  cartHeader: 'cart.header',
  cartTotalAmount: 'cart.totalAmount',
  cartTableHeaderItem: 'cart.tableHeader.item',
  cartTableHeaderPrice: 'cart.tableHeader.price',
  cartTableHeaderQuantity: 'cart.tableHeader.quantity',
  cartTableHeaderTotal: 'cart.tableHeader.total',
  cartTableLearnMore: 'cart.table.learnMore',
  cartTableHideItems: "cart.table.hideItems",
  cartTableShowItems: "cart.table.showItems",
  cartAutoRemovedItems: 'cart.autoRemoved.items',
  cartBadgeMaxQuantity: 'cart.badge.maxQuantity',
  cartBadgeSoldOut: 'cart.badge.soldOut',
  cartMessageRounded: "cart.message.rounded",
  cartMessageFees: "cart.message.fees",

  checkoutCompletionHeader: 'checkout.completion.header',
  checkoutCompletionAddressTokenCard: 'checkout.completion.address.tokenCard',
  checkoutCompletionAddressWallet: 'checkout.completion.address.wallet',
  checkoutCompletionTokenCardEnsureDeposit: 'checkout.completion.tokenCard.ensureDeposit',
  checkoutFinishButton: "checkout.finish.button",

  checkoutFeeMethod: 'checkout.fee.method',
  checkoutFeeMethodDescription: "checkout.fee.method.description",
  checkoutFeeCountryDescription: "checkout.fee.country.description",
  checkoutFeeTotal: 'checkout.fee.total',

  checkoutCustomerDetailsPurpose: 'checkout.customerDetails.purpose',
  checkoutCustomerDetailsFormInvoiceInfo:
    'checkout.customerDetails.form.invoiceInfo',
  checkoutBankBeneficiary: 'checkout.bank.beneficiary',
  checkoutOrderWarning: 'checkout.order.warning',
  checkoutPaymentMethodsBankDetailsIntermediaryAccount:
    'checkout.payment.methods.bankDetails.intermediary.account',
  checkoutPaymentMethodsBankDetailsAmountAndReference:
    'checkout.payment.methods.bankDetails.amountAndReference',
  checkoutPaymentMethodsBankDetailsAmount:
    'checkout.payment.methods.bankDetails.amount',
  checkoutPaymentMethodsBankDetailsPaymentReference:
    'checkout.payment.methods.bankDetails.paymentReference',
  checkoutPaymentMethodsBankTransferTransferToBeneficiary:
    'checkout.payment.methods.bankTransfer.transferToBeneficiary',
  checkoutPaymentMethodsBankTransferClickToCopy:
    'checkout.payment.methods.bankTransfer.clickToCopy',
  checkoutPaymentMethodSelect: 'checkout.payment.method.select',
  checkoutPaymentMethodLimit: 'checkout.payment.method.limit',
  checkoutPaymentWarning: 'checkout.payment.warning',
  checkoutCollectionMethodSelect: 'collectionMethod.methodSelect',
  collectionMethodDetailsHeader: 'collectionMethod.details.header',
  collectionMethodDetailsHeaderSmallScreen: 'collectionMethod.details.header.smallScreen',
  collectionMethodDetailsEthAddress: 'collectionMethod.details.ethAddress',
  collectionMethodDetailsDescriptionSmallScreen: 'collectionMethod.details.description.smallScreen',
  collectionFee: "collection.fee",
  collectionTokenCardDescription: "collection.tokenCard.description",
  collectionSignableWalletTitle: "collection.signableWallet.title",
  collectionSignableWalletDescription: "collection.signableWallet.description",
  collectionNonSignableWalletTitle: "collection.nonSignableWallet.title",
  collectionNonSignableWalletDescription: "collection.nonSignableWallet.description",
  collectionOneTimeWalletTitle: "collection.oneTimeWallet.title",
  collectionOneTimeWalletDescription: "collection.oneTimeWallet.description",
  collectionMethodInputOneTimeAddress: 'collectionMethod.inputOneTimeAddress',
  collectionMethodInputOneTimeAddressFormat: 'collectionMethod.inputOneTimeAddress.format',
  collectionMethodInputOneTimeAddressSampleAddress: 'collectionMethod.inputOneTimeAddress.sampleAddress',
  collectionMethodInputOneTimeAddressNameLabel: 'collectionMethod.inputOneTimeAddress.nameLabel',
  checkoutHeaderNavigationSelectWallet:
    'checkout.headerNavigation.selectWallet',
  checkoutHeaderNavigationDetails: 'checkout.headerNavigation.details',
  checkoutHeaderNavigationYourOrder: 'checkout.headerNavigation.yourOrder',
  checkoutHeaderNavigationPayment: 'checkout.headerNavigation.payment',
  checkoutHeader: 'checkout.header',
  checkoutTimerDraftOrderMessage: 'checkout.timer.draftOrderMessage',
  checkoutTimerBankTransferMessage: 'checkout.timer.bankTransferMessage',
  checkoutTimeExpiredPrimaryMessage: 'checkout.timeExpired.primaryMessage',
  checkoutTimeExpiredSecondaryMessage: 'checkout.timeExpired.secondaryMessage',
  checkoutTimeExpiredGoBack: 'checkout.timeExpired.GoBack',

  configManagementHeader: 'configManagement.header',
  configManagementPlaceholder: 'configManagement.placeholder',
  configManagementMenuTenantConfiguration:
    'configManagement.menu.tenantConfiguration',
  configManagementMenuAvailableAssets: 'configManagement.menu.availableAssets',
  configManagementMenuPurchaseConfiguration:
    'configManagement.menu.purchaseConfiguration',
  configManagementMenuRedemptionMethods:
    'configManagement.menu.redemptionMethods',
  configManagementMenuKyc: 'configManagement.menu.kyc',

  copiedToClipboardInfo: 'copiedToClipboard.info',

  cookieNoticeDescription: 'cookieNotice.description',
  cookieNoticeSettings: 'cookieNotice.settings',
  cookieModalDescription: 'cookieModal.description',
  cookieModalNecessaryCookie: 'cookieModal.necessaryCookie',
  cookieModalNonNecessaryCookie: 'cookieModal.nonNecessaryCookie',
  cookieModalNecessaryCookieDescription:
    'cookieModal.necessaryCookie.description',
  cookieModalNonNecessaryCookieDescription:
    'cookieModal.nonNecessaryCookie.description',
  cookieModalFooterDescription: 'cookieModal.footer.description',

  dropdownTextFieldDeselectTextDefaultText:
    'dropdown.text.field.deselectText.defaultText',

  enumCollectMethodTokenCardName: 'enum.collectMethod.tokenCard',
  enumCollectMethodOwnWalletName: 'enum.collectMethod.ownWallet',
  enumCollectMethodOwnWalletNsName: 'enum.collectMethod.ownWallet.ns',
  enumOrderStatusPaymentError: 'enum.orderStatus.paymentError',
  enumOrderStatusPaymentTimeout: 'enum.orderStatus.paymentTimeout',
  enumOrderStatusSendingError: 'enum.orderStatus.sendingError',
  enumOrderStatusWaitingForPayment: 'enum.orderStatus.waitingForPayment',
  enumOrderStatusDraft: 'enum.orderStatus.draft',
  enumOrderStatusAborted: 'enum.orderStatus.aborted',
  enumOrderStatusReceived: 'enum.orderStatus.received',
  enumOrderStatusSent: 'enum.orderStatus.sent',
  enumOrderStatusError: 'enum.orderStatus.error',
  enumInvalidEnum: 'enum.invalidEnum',
  enumNone: 'enum.none',
  enumPayoutMethodBankTransfer: 'enum.payoutMethod.bankTransfer',
  enumPayoutMethodBitcoin: 'enum.payoutMethod.bitcoin',
  enumPayoutMethodEthereum: 'enum.payoutMethod.ethereum',
  enumPayoutMethodEthereumToken: 'enum.payoutMethod.ethereumToken',
  enumPayoutMethodPhysicalDelivery: 'enum.payoutMethod.physicalDelivery',
  enumRedemptionStatusDraft: 'enum.redemptionStatus.draft',
  enumRedemptionStatusPaymentTimeout: 'enum.redemptionStatus.paymentTimeout',
  enumRedemptionStatusNew:
    'enum.redemptionStatus.new',
  enumRedemptionStatusProcessing: 'enum.redemptionStatus.processing',
  enumRedemptionStatusPayoutError: 'enum.redemptionStatus.payoutError',
  enumRedemptionStatusReceived: 'enum.redemptionStatus.received',
  enumRedemptionStatusPaid: 'enum.redemptionStatus.paid',
  enumRedemptionStatusAborted: 'enum.redemptionStatus.aborted',
  enumRedemptionStatusError: 'enum.redemptionStatus.error',

  enumTransactionStatusNotInitiated: 'enum.transactionStatus.notInitiated',
  enumTransactionStatusReceived: 'enum.transactionStatus.received',
  enumTransactionStatusSubmitted: 'enum.transactionStatus.submitted',
  enumTransactionStatusCommitted: 'enum.transactionStatus.committed',
  enumTransactionStatusConfirmed: 'enum.transactionStatus.confirmed',
  enumTransactionStatusRevokedByBlockchain:
    'enum.transactionStatus.revokedByBlockchain',
  enumTransactionStatusRejectedByUser: 'enum.transactionStatus.rejectedByUser',
  enumTransactionStatusFailed: 'enum.transactionStatus.failed',

  enumWalletTypeBtc: 'enum.walletType.btc',
  enumWalletTypeEth: 'enum.walletType.eth',

  errorConfirmationRequired: 'error.confirmationRequired',
  errorAdditionalData: 'error.additionalData',
  errorMessageLoadEnvSettings: 'error.message.loadEnvSettings',
  errorMessageAuthProcessFail: 'error.message.authProcessFail',
  errorMessageLoadConfig: 'error.message.load',
  errorMessageLoadStylesheetFavicon: 'error.message.loadStylesheetFavicon',
  errorMessageFillOutThisField: 'error.message.fillOutThisField',
  errorSignup: 'error.signup',
  errorBankAccountsCardsStoreBankAccount:
    'error.bankAccountsCards.storeBankAccount',
  errorBankAccountsCardsDeleteBankAccount:
    'error.bankAccountsCards.deleteBankAccount',
  errorKycDetailsCardsSubmission: 'error.kycDetailsCards.submission',
  errorKycDetailsTableLoading: 'error.kycDetailsTable.loading',
  errorProfileCardAvatarToManySelectedFiles:
    'error.profileCard.avatar.toManySelectedFiles',
  errorProfileCardAvatarSelectOnlyOneFile:
    'error.profileCard.avatar.selectOnlyOneFile',
  errorProfileCardAvatarInvalidFileType:
    'error.profileCard.avatar.invalidFileType',
  errorProfileCardAvatarSelectFileType:
    'error.profileCard.avatar.selectFileType',
  errorProfileCardAvatarFileSizeTooLarge:
    'error.profileCard.avatar.fileSizeTooLarge',
  errorProfileCardAvatarSelectFileSize:
    'error.profileCard.avatar.selectFileSize',
  errorProfileCardAvatarCouldNotRemove:
    'error.profileCard.avatar.couldNotRemove',
  errorProfileCardNoCredentials: 'error.profileCard.noCredentials',
  errorProfileCardNoUpdateProfileImage:
    'error.profileCard.noUpdateProfileImage',
  errorWalletCardAddWalletAlreadyConnected:
    'error.walletCard.addWallet.alreadyConnected',
  errorWalletCardAddWalletCouldNotSave:
    'error.walletCard.addWallet.couldNotSave',
  errorWalletCardDeleteWalletCouldNotDelete:
    'error.walletCard.deleteWallet.couldNotDelete',
  errorKycProcessProvideAllFiles: 'error.kyc.process.provideAllFiles',
  errorKycCreateCase: 'error.kyc.createCase',
  errorOrderHistoryNoRequiredData: 'error.orderHistory.noRequiredData',
  errorRedeemHistoryNoRequiredData: 'error.redeemHistory.noRequiredData',
  errorAccountNoRequiredData: 'error.account.noRequiredData',
  errorConfigManagementCouldNotParseLoadConfig:
    'error.configManagement.couldNotParseLoadConfig',
  errorConfigManagementInvalidJson: 'error.configManagement.invalidJson',
  errorConfigManagementSaveConfigFail: 'error.configManagement.saveConfigFail',
  errorKycManagementCouldNotUpdateKycLevel:
    'error.kycManagement.couldNotUpdateKycLevel',
  errorKycManagementDetailsLoadError: 'error.kycManagement.details.loadError',
  errorKycManagementDetailsUpdateRecordStatusError:
    'error.kycManagement.details.updateRecordStatusError',
  errorKycManagementOverviewLoadingError:
    'error.kycManagement.overview.loadingError',
  errorKycManagementOverviewExportError:
    'error.kycManagement.overview.exportError',
  errorPurchaseManagementDetailsDownloadInvoiceFail:
    'error.purchaseManagement.details.downloadInvoiceFail',
  errorPurchaseManagementDetailsOrderStatusUpdateError:
    'error.purchaseManagement.details.orderStatus.updateError',
  errorPurchaseManagementDetailsLoadingError:
    'error.purchaseManagement.details.loadingError',
  errorPurchaseManagementDetailsSendingFailure:
    'error.purchaseManagement.details.sendingFailure',
  errorPurchaseManagementDetailsWalletAddressNotUpdated:
    'error.purchaseManagement.details.walletAddressNotUpdated',
  errorPurchaseManagementOverviewLoadingError:
    'error.purchaseManagement.overview.loadingError',
  errorPurchaseManagementOverviewExportRecordsFail:
    'error.purchaseManagement.overview.exportRecordsFail',
  errorRedeemManagementDetailsLoadingError:
    'error.redeemManagement.details.loadingError',
  errorRedeemManagementDetailsNoRedemptionStatusUpdate:
    'error.redeemManagement.details.noRedemptionStatusUpdate',
  errorRedeemManagementRedeemRecordsExportFailure:
    'error.redeemManagement.redeemRecordsExportFailure',
  errorCartAssetsLoadingError: 'error.cart.assetsLoadingError',
  errorCartAssetsLoadingErrorWithMessage:
    'error.cart.assetsLoadingErrorWithMessage',
  errorCartCustomerDetailsLoadingError:
    'error.cart.customerDetails.loadingError',
  errorOrderCreate: "error.order.create",
  errorCartCheckoutPaymentProviderLaunchError:
    'error.cart.checkout.payment.providerLaunchError',
  errorCartCheckoutPaymentUpdateStatusError:
    'error.cart.checkout.payment.updateStatusError',
  errorCartCheckoutPaymentOrderUnsuccessful:
    'error.cart.checkout.payment.orderUnsuccessful',
  errorCartcollectionMethodRetrievingAddressFailure:
    'error.cart.collectionMethod.retrievingAddressFailure',
  errorPurchaseAssetLoadingError: 'error.purchase.assetLoadingError',
  errorRedemptionDetailsPdf: 'error.redemption.details.pdf',
  errorRedeemDetailsUserDataRetrievingError:
    'error.redeem.details.userDataRetrievingError',
  errorRedeemDetailsRedemptionCreationFailure:
    'error.redeem.details.redemptionCreationFailure',
  errorRedeemSummaryRedemptionCreationFailure:
    'error.redeem.summary.redemptionCreationFailure',
  errorDefaultMessage: 'error.defaultMessage',
  errorToastError: 'error.toastError',
  errorToastErrorFinalNote: 'error.toastError.finalNote',
  errorToastErrorClickMessage: 'error.toastError.clickMessage',
  errorStatesCartLoadCartError: 'error.states.cart.loadCartError',
  errorStatesCartAddItemToCartError: 'error.states.cart.addItemToCartError',
  errorStatesCartRemoveItemToCartError:
    'error.states.cart.removeItemToCartError',
  errorStatesCartEmptyCartError: 'error.states.cart.emptyCartError',
  errorStatesCartSetQuantityError: 'error.states.cart.setQuantityError',

  errorPageHeader: 'errorPage.header',
  errorPageNotFoundHeading: 'errorPage.notFound.heading',
  errorPageNotFoundText: 'errorPage.notFound.text',
  errorPageServerProblemHeading: 'errorPage.serverProblem.heading',
  errorPageServerProblemText: 'errorPage.serverProblem.text',
  errorPageServiceUnavailableHeading: 'errorPage.serviceUnavailable.heading',
  errorPageServiceUnavailableText: 'errorPage.serviceUnavailable.text',
  errorPageAccessDeniedHeading: 'errorPage.accessDenied.heading',
  errorPageAccessDeniedText: 'errorPage.accessDenied.text',
  errorPageTryAgain: 'errorPage.tryAgain',

  featureToggleActivate: 'featureToggle.activate',
  featureToggleDisable: 'featureToggle.disable',
  featureToggleModalPurchaseFeatureName:
    'featureToggleModal.purchase.featureName',
  featureToggleModalPurchaseActivateText:
    'featureToggleModal.purchase.activateText',
  featureToggleModalPurchaseDisableText:
    'featureToggleModal.purchase.disableText',
  featureToggleModalRedeemFeatureName: 'featureToggleModal.redeem.featureName',
  featureToggleModalRedeemActivateText:
    'featureToggleModal.redeem.activateText',
  featureToggleModalRedeemDisableText: 'featureToggleModal.redeem.disableText',
  featureToggleModalKycFeatureName: 'featureToggleModal.kyc.featureName',
  featureToggleModalKycActivateText: 'featureToggleModal.kyc.activateText',
  featureToggleModalKycDisableText: 'featureToggleModal.kyc.disableText',
  featureToggleModalCustomPurchaseFeatureName:
    'featureToggleModal.customPurchase.featureName',
  featureToggleModalCustomPurchaseActivateText:
    'featureToggleModal.customPurchase.activateText',
  featureToggleModalCustomPurchaseDisableText:
    'featureToggleModal.customPurchase.disableText',

  helperOrderUser: 'helper.order.user',
  helperOrderBilled: 'helper.order.billed',
  helperOrderCollectedVia: 'helper.order.collectedVia',
  helperOrderPayVia: 'helper.order.payVia',
  helperOrderStatus: 'helper.order.status',
  helperRedeemId: 'helper.redeem.id',
  helperRedeemUser: 'helper.redeem.user',
  helperRedeemMethod: 'helper.redeem.method',
  helperRedeemStatus: 'helper.redeem.status',
  helperValidatorArray: 'helper.validator.array',
  helperValidatorArrayEmpty: "helper.validator.array.empty",
  helperValidatorObject: 'helper.validator.object',
  helperValidatorJson: 'helper.validator.json',
  helperValidatorString: 'helper.validator.string',
  helperValidatorEmptyString: 'helper.validator.emptyString',
  helperValidatorStringDigits: 'helper.validator.stringDigits',
  helperValidatorHexString: 'helper.validator.hexString',
  helperValidatorLowerBoundAndUpperBound:
    'helper.validator.lowerBoundAndUpperBound',
  helperValidatorNumber: 'helper.validator.number',
  helperValidatorPositiveNumber: 'helper.validator.positiveNumber',
  helperValidatorNonNegativeInteger: 'helper.validator.nonNegativeInteger',
  helperValidatorNonNegativeNumber: 'helper.validator.nonNegativeNumber',
  helperValidatorNumberNumericString: 'helper.validator.numberNumericString',
  helperValidatorLength: 'helper.validator.length',
  helperValidatorDecimalPlaces: 'helper.validator.decimalPlaces',

  imprintHeader: 'imprint.header',
  imprintCompany: 'imprint.company',
  imprintAddress: 'imprint.address',
  imprintPhone: 'imprint.phone',
  imprintEmail: 'imprint.email',

  about: "about",
  aboutTitle: "about.title",
  aboutCopyButton: "about.copy.button",
  aboutCopyNotification: "about.copy.notification",
  aboutTerms: "about.terms",
  aboutDp: "about.dp",

  kycTiersPageHeader: 'kyc.tiers.pageHeader',
  kycGuideStep1: 'kyc.guide.step.1',
  kycGuideStep2: 'kyc.guide.step.2',
  kycGuideStep2Manual: 'kyc.guide.step.2.manual',
  kycTiersGuideCheckTierStatus: 'kyc.guide.step.3',
  kycTiersGuideUseUnlockedFeatures: 'kyc.guide.step.4',
  kycTiersHeader: 'kyc.tiers.header',
  kycTiersAvailabilityPurchase: 'kyc.tiers.availability.purchase',
  kycTiersAvailabilityRedemption: 'kyc.tiers.availability.redemption',
  kycTiersPaymentMethodBankTransfer: 'kyc.tiers.paymentMethod.bankTransfer',
  kycTiersPaymentMethodCreditCard: 'kyc.tiers.paymentMethod.creditCard',
  kycTiersPaymentMethodCryptoCurrency:
    'kyc.tiers.paymentMethod.cryptocurrency',
  kycTiersPaymentMethodTemplate: 'kyc.tiers.paymentMethodTemplate',
  kycTiersRedemptionTemplate: 'kyc.tiers.redemptionTemplate',
  kycTierUpgrade: 'kyc.tierUpgrade',
  kycGetTier: 'kyc.getTier',
  kycProcessing: 'kyc.processing',
  kycProcessingMessage: 'kyc.processingMessage',
  kycGetTierToSelect: 'kyc.getTierToSelect',
  kycGetTierToContinue: 'kyc.getTierToContinue',
  kycGetTierToBuy: 'kyc.getTierToBuy',
  kycLoadUserTierError: 'kyc.loadUserTierError',
  kycPoviderRedirectonModalHeading: 'kyc.providerRedirectonModal.heading',
  kycPoviderRedirectonModalText: 'kyc.providerRedirectionModal.text',
  kycManualConfirmationHeading: "kyc.manual.confirmation.heading",
  kycManualConfirmationText: "kyc.manual.confirmation.text",

  kycLevelInfoBoxNotVerified: 'kyc.level.infoBox.notVerified',
  kycLevelInfoBoxTier: 'kyc.level.infoBox.tier',
  kycCaseStatusOpen: 'kyc.case.status.open',
  kycCaseStatusDeclined: 'kyc.case.status.declined',
  kycCaseStatusApproved: 'kyc.case.status.approved',
  kycCaseTooltipApprove: 'kyc.case.tooltip.approve',
  kycCaseTooltipDecline: 'kyc.case.tooltip.decline',

  kycManagementRecordInformationHeader:
    'kycManagement.recordInformation.header',
  kycManagementRecordInformationProvider:
    'kycManagement.recordInformation.provider',
  kycManagementRecordInformationPhoto: 'kycManagement.recordInformation.photo',
  kycManagementRecordInformationResidence:
    'kycManagement.recordInformation.residence',
  kycManagementRecordInformationStatus:
    'kycManagement.recordInformation.status',
  kycManagementUserInformationTierLabel:
    'kycManagement.userInformation.tierLabel',
  kycManagementDetailsOpen: 'kycManagement.details.open',
  kycManagementDetailsApproved: 'kycManagement.details.approved',
  kycManagementDetailsDeclined: 'kycManagement.details.declined',
  kycManagementDetailsDropdownOpen: 'kycManagement.details.dropdown.open',
  kycManagementDetailsDropdownApproved:
    'kycManagement.details.dropdown.approved',
  kycManagementDetailsDropdownDeclined:
    'kycManagement.details.dropdown.declined',
  kycManagementDetailsHeader: 'kycManagement.details.header',
  kycManagementDetailsKycHistory: 'kycManagement.details.kycHistory',

  kycManagementDetailsStatusUpdateDate:
    'kycManagement.details.statusUpdateDate',
  kycManagementDetailsRequestStatus: 'kycManagement.details.requestStatus',
  kycManagementDetailsCaseStatus: 'kycManagement.details.caseStatus',
  kycManagementDetailsOpenDate: 'kycManagement.details.openDate',
  kycManagementDetailsCompletionDate: 'kycManagement.details.completionDate',
  kycManagementDetailsProviderResponse:
    'kycManagement.details.providerResponse',
  kycManagementDetailsAdminReason: 'kycManagement.details.adminReason',
  kycManagementDetailsReadResponse: 'kycManagement.details.readResponse',
  kycManagementDetailsReadFullReason: 'kycManagement.details.readFullReason',
  kycManagementDetailsDeclineTitle: 'kycManagement.details.declineTitle',
  kycManagementDetailsProviderTierAtStart: 'kycManagement.details.tierAtStart',
  kycManagementDetailsProviderRequestedTier:
    'kycManagement.details.requestedTier',

  kycManagementDetailsTableId: 'kycManagement.details.table.id',
  kycManagementDetailsTableOpenDate: 'kycManagement.details.table.openDate',
  kycManagementDetailsTableCompletionDate:
    'kycManagement.details.table.completionDate',
  kycManagementDetailsTableCaseStatus: 'kycManagement.details.table.caseStatus',

  kycManagementOverviewSearchByUser: 'kycManagement.overview.searchByUser',
  kycManagementOverviewId: 'kycManagement.overview.id',
  kycManagementOverviewUser: 'kycManagement.overview.user',
  kycManagementOverviewOpenCompletionDate:
    'kycManagement.overview.openCompletionDate',
  kycManagementOverviewCaseStatus: 'kycManagement.overview.caseStatus',
  kycManagementOverviewRequestStatus: 'kycManagement.overview.requestStatus',
  kycManagementOverviewUpdated: 'kycManagement.overview.updated',
  kycManagementStatusSettings: 'kycManagement.statusSettings',
  kycManagementAutoApproveTitle: 'kycManagement.autoApprove.title',
  kycManagementAutoApproveDescription: 'kycManagement.autoApprove.description',
  kycManagementAutoDeclineTitle: 'kycManagement.autoDecline.title',
  kycManagementAutoDeclineDescription: 'kycManagement.autoDecline.description',
  kycManagementAutoEnable: 'kycManagement.auto.enable',
  kycManagementAutoDisable: 'kycManagement.auto.disable',

  modalEscape: 'modal.escape',

  orderHistoryHeader: 'orderHistory.header',

  purchaseAssetCardPriceTag: 'purchase.assetCard.priceTag',

  addToCartMaxQuantity: 'addToCart.maxQuantity',
  addToCartNotAvailable: 'addToCart.notAvailable',
  addToCartMaxQuantityInCart: 'addToCart.maxQuantityInCart',
  addToCartRounded: "addToCart.rounded",
  addToCartLoading: "addToCart.loading",
  addToCartIsInTheCartUOM: 'addToCart.isInTheCartUOM',
  addToCartAreInTheCartUOM: 'addToCart.areInTheCartUOM',
  addToCartIsInTheCart: 'addToCart.isInTheCart',
  addToCartAreInTheCart: 'addToCart.areInTheCart',

  purchaseConfigurationAssetsForPurchasingTitle:
    'purchaseConfiguration.assetsForPurchasing.title',
  purchaseConfigurationPaymentConfigurationsTitle:
    'purchaseConfiguration.paymentConfigurations.title',
  purchaseConfigurationCollectionMethodsTitle:
    'purchaseConfiguration.collectionMethods.title',

  purchaseManagementDetailsCheckSentInvoice:
    'purchaseManagement.details.checkSentInvoice',
  purchaseManagementDetailsSummaryTotal:
    'purchaseManagement.details.summary.total',
  purchaseManagementDetailsSendConfirmationMessage:
    'purchaseManagement.details.send.confirmationMessage',
  purchaseManagementDetailsSendConfirmationTitle:
    'purchaseManagement.details.send.confirmationTitle',

  redeemFinishTitle: 'redeem.finish.title',
  redeemFinishSubtitle: 'redeem.finish.subtitle',
  redeemFinishDescription: 'redeem.finish.description',
  redeemFinishRedemptionReferenceCode: 'redeem.finish.redemptionReferenceCode',
  redeemFinishPaymentDetails: 'redeem.finish.paymentDetails',
  redeemFinishPaymentGross: 'redeem.finish.grossPay',
  redeemFinishPaymentNet: 'redeem.finish.netPay',
  redeemFinishPaymentAsset: 'redeem.finish.assetPay',
  redeemFinishPayoutDetails: 'redeem.finish.payoutDetails',
  redeemFinishAmountReceive: 'redeem.finish.amountReceive',
  redemptionFinishButton: "redemption.finish.button",

  redeemErrorTitle: 'redeem.error.title',
  redeemErrorDescription: 'redeem.error.description',
  redeemErrorStartOver: 'redeem.error.startOver',
  redeemMethodSelectionPayWith: 'redeem.methodSelection.payWith',
  redeemQrInfo: 'redeem.qr.info',
  redeemQrDescription: 'redeem.qr.description',
  redeemQrInstructions: 'redeem.qr.instructions',
  redeemQrCopy: 'redeem.qr.copy',
  redeemQrData: 'redeem.qr.data',
  redeemTransferInfo: 'redeem.transfer.info',
  redeemTransferDescription: 'redeem.transfer.description',
  redeemSummaryBankBankAddressCity: 'redeem.summary.bank.bankAddressCity',
  redeemSummaryBankBankAddressZip: 'redeem.summary.bank.bankAddressZip',
  redemptionSummaryHeader: 'redemption.summary.header',
  redeemHeaderNavigationSelectMethod: 'redeem.headerNavigation.selectMethod',
  redeemHeaderNavigationDetails: 'redeem.headerNavigation.details',
  redeemHeaderNavigationSummary: 'redeem.headerNavigation.summary',
  redeemHeaderNavigationFinish: 'redeem.headerNavigation.finish',

  orderDetails: 'order.details',
  orderInformation: 'order.information',
  orderInvoiceInformation: 'order.invoiceInformation',
  orderShippingInformation: 'order.shippingInformation',
  orderPaidVia: 'order.paidVia',
  orderReceivingMethod: 'order.receivingMethod',
  orderStatus: 'order.status',
  orderPurchaseSummary: 'order.purchaseSummary',
  orderUniqueAssetId: 'order.uniqueAssetId',
  orderAssetName: 'order.assetName',
  orderTransactionStatus: 'order.transactionStatus',
  orderWalletAddressEdit: 'order.walletAddress.edit',
  orderWalletAddressNew: 'order.walletAddress.new',
  orderWalletAddressCurrent: 'order.walletAddress.current',

  redemptionDetailsPdf: 'redemption.details.pdf',
  
  redemptionAssetName: 'redemption.assetName',
  redemptionUniqueAssetId: 'redemption.uniqueAssetId',
  redemptionId: 'redemption.id',
  redemptionMethod: 'redemption.method',
  redemptionStatus: 'redemption.status',
  redemptionGross: 'redemption.gross',
  redemptionPayout: 'redemption.payout',

  redemptionSelectWalletHeader: 'redemption.selectWalletHeader',
  redemptionSelectWalletLabel: 'redemption.selectWalletLabel',
  redemptionWalletChangesWarning: 'redemption.walletChangesWarning',
  redemptionAnotherWallet: 'redemption.anotherWallet',
  redemptionChooseExistingWallet: 'redemption.chooseExistingWallet',

  redemptionSelectBankAccountHeader: 'redemption.selectBankAccountHeader',
  redemptionSelectBankAccountLabel: 'redemption.selectBankAccountLabel',
  redemptionBankAccountChangesWarning: 'redemption.bankAccountChangesWarning',
  redemptionAnotherBankAccount: 'redemption.anotherBankAccount',
  redemptionChooseExistingBankAccount: 'redemption.chooseExistingBankAccount',
  redemptionAddToMyBankAccounts: 'redemption.addToMyBankAccounts',

  redemptionBeneficiaryEnterDetails: "redemption.beneficiary.enterDetails",
  redemptionBeneficiaryInfo: "redemption.beneficiary.info",
  redemptionBeneficiaryAddress: "redemption.beneficiary.address",

  redemptionPayReceiveHeading: 'redemption.payReceive.heading',
  redemptionPayReceiveYouPay: 'redemption.payReceive.youPay',
  redemptionPayReceiveYouReceive: 'redemption.payReceive.youReceive',
  redemptionPayReceiveLimits: "redemption.payReceive.limits",
  redemptionPayReceiveStep: "redemption.payReceive.step",
  redemptionPayReceiveCountryChangeWarning: "redemption.payReceive.countryChangeWarning",
  redemptionPayReceiveRoundingWarning: "redemption.payReceive.roundingWarning",
  redemptionPayReceiveRoundingExplanation: "redemption.payReceive.roundingExplanation",
  redemptionPayReceiveRoundUp: "redemption.payReceive.roundUp",
  redemptionPayReceiveNetAmount: "redemption.payReceive.netAmount",
  redemptionPayReceiveTotalAmount: "redemption.payReceive.totalAmount",
  redemptionPayReceiveFees: "redemption.payReceive.fees",

  redemptionAdditionalDetailsTitle: "redemption.additionalDetails.title",

  redemptionAbort: 'redemption.abort',
  redemptionFeePercentage: "redemption.fee.percentage",
  redemptionFeeIssuer: "redemption.fee.issuer",
  redemptionFeeIssuerDescription: "redemption.fee.issuer.description",
  redemptionFeeMethodDescription: "redemption.fee.method.description",
  redemptionFeeCountryDescription: "redemption.fee.country.description",

  redeemHistoryHeader: 'redeemHistory.header',
  redeemManagementDetailsHeader: 'redeemManagement.details.header',
  redeemManagementDetailsRequestInformationHeader:
    'redeemManagement.details.requestInformation.header',
  redeemManagementDetailsRequestInformationGrossAmount:
    'redeemManagement.details.requestInformation.amountToPay',
  redeemManagementDetailsRequestInformationNetAmount:
    'redeemManagement.details.requestInformation.netAmount',
  redeemManagementDetailsRequestInformationNetAmountValue:
    'redeemManagement.details.requestInformation.netAmountValue',
  redeemManagementDetailsRedemptionRequestHeader:
    'redeemManagement.details.redemptionRequest.header',
  redeemManagementDetailsRedemptionRequestShowFees:
    'redeemManagement.details.redemptionRequest.showFees',
  redeemManagementDetailsRedemptionRequestHideFees:
    'redeemManagement.details.redemptionRequest.hideFees',

  shuftiProRequestReceived: 'shuftiPro.RequestReceived',
  shuftiProRequestPending: 'shuftiPro.RequestPending',
  shuftiProRequestTimeout: 'shuftiPro.RequestTimeout',
  shuftiProVerificationCancelled: 'shuftiPro.VerificationCancelled',
  shuftiProVerificationAccepted: 'shuftiPro.VerificationAccepted',
  shuftiProVerificationDeclined: 'shuftiPro.VerificationDeclined',
  shuftiProVerificationError: 'shuftiPro.VerificationError',

  sidebarShop: 'sidebar.shop',
  sidebarRedemption: 'sidebar.redemption',
  sidebarKycCases: 'sidebar.kycCases',
  sidebarOrderManagement: 'sidebar.orderManagement',
  sidebarRedemptionManagement: 'sidebar.redemptionManagement',
  sidebarConfigManagement: 'sidebar.configManagement',
  sidebarJsonConfigManagement: 'sidebar.jsonConfigManagement',
  sidebarLoginRegister: 'sidebar.loginRegister',
  sidebarImprint: 'sidebar.imprint',
  sidebarKycReminder: 'sidebar.kycReminder',
  sidebarAndroid: 'sidebar.android',
  sidebarIOS: 'sidebar.iOS',

  headerShop: 'header.shop',
  headerRedemption: 'header.redemption',
  headerKycCases: 'header.kycCases',
  headerOrderManagement: 'header.OrderManagement',
  headerRedemptionManagement: 'header.redemptionManagement',

  tableFilterPlaceholder: 'table.filter.placeholder',
  tableFilterSelectAll: 'table.filter.select.all',
  tablePaginationPage: 'table.pagination.page',
  tablePaginationGoToPage: 'table.pagination.goToPage',

  signupTitle: "signup.title",
  signupCodeTitle: "signup.code.title",
  signupCodeDescription: "signup.code.description",
  signupCodeLabel: "signup.code.label",
  signupCodePlaceholder: "signup.code.placeholder",
  signupEmailTitle: "signup.email.title",
  signupEmailDescription: "signup.email.description",
  signupEmailCooldown: "signup.email.cooldown",
  signupEmailResend: "signup.email.resend",
  signupEmailConfirm: "signup.email.confirm",
  signupAdminTitle: "signup.admin.title",
  signupAdminDescription: "signup.admin.description",
  signupAdminStatus: "signup.admin.status",
  signupNotificationPurchase: "signup.notification.purchase",
  signupNotificationRedemption: "signup.notification.redemption",
  signupNotificationSidebar: "signup.notification.sidebar",
  signupNotificationSuccess: "signup.notification.success",
  signupBlockedTitle: "signup.blocked.title",
  signupBlockedDescription: "signup.blocked.description",

  tenantSettingsGeneralSettingsTitle: 'tenantSettings.generalSettings.title',
  tenantSettingsLanguagesAndCurrenciesTitle:
    'tenantSettings.languagesAndCurrencies.title',
  tenantSettingsBrandingSettingsTitle: 'tenantSettings.brandingSettings.title',
  tenantSettingsFeaturesMain: 'tenantSettings.features.main',
  tenantSettingsFeaturesAdditional: 'tenantSettings.features.additional',
  tenantSettingsFeaturesPurchaseTitle: 'tenantSettings.features.purchase.title',
  tenantSettingsFeaturesPurchaseDescription:
    'tenantSettings.features.purchase.description',
  tenantSettingsFeaturesPurchaseTooltip:
    'tenantSettings.features.purchase.tooltip',
  tenantSettingsFeaturesRedeemTitle: 'tenantSettings.features.redeem.title',
  tenantSettingsFeaturesRedeemDescription:
    'tenantSettings.features.redeem.description',
  tenantSettingsFeaturesKycTitle: 'tenantSettings.features.kyc.title',
  tenantSettingsFeaturesKycDescription:
    'tenantSettings.features.kyc.description',
  tenantSettingsFeaturesCustomPurchaseTitle:
    'tenantSettings.features.customPurchase.title',
  tenantSettingsFeaturesCustomPurchaseDescription:
    'tenantSettings.features.customPurchase.description',
  tenantSettingsFeaturesRestrictedModeTitle:
    'tenantSettings.features.restrictedMode.title',
  tenantSettingsFeaturesRestrictedModeDescription:
    'tenantSettings.features.restrictedMode.description',
  tenantSettingsFeaturesRestrictedModeEnableTitle:
    'tenantSettings.features.restrictedMode.enable.title',
  tenantSettingsFeaturesRestrictedModeEnableDescription:
    'tenantSettings.features.restrictedMode.enable.description',
  tenantSettingsFeaturesRestrictedModeDisableTitle:
    'tenantSettings.features.restrictedMode.disable.title',
  tenantSettingsFeaturesRestrictedModeDisableDescription:
    'tenantSettings.features.restrictedMode.disable.description',

  tenantSettingsMobileTitle: 'tenantSettings.mobile.title',
  tenantSettingsMobileDescription: 'tenantSettings.mobile.description',
  tenantSettingsMobileInputLabel: 'tenantSettings.mobile.input.label',

  tenantSettingsAdminTableHeading: 'tenantSettings.admin.table.heading',
  tenantSettingsAdminTableTooltip: 'tenantSettings.admin.table.tooltip',
  tenantSettingsAdminTableAdd: 'tenantSettings.admin.table.add',
  tenantSettingsAdminTableAddDescription:
    'tenantSettings.admin.table.add.description',
  tenantSettingsAdminTableSearchUser: 'tenantSettings.admin.table.searchUser',
  tenantSettingsAdminTableSelectionAmount:
    'tenantSettings.admin.table.selectionAmount',
  tenantSettingsAdminTableRemove: 'tenantSettings.admin.table.remove',
  tenantSettingsAdminTableRemoveConfirmation:
    'tenantSettings.admin.table.remove.confirmation',
  tenantSettingsAdminTableRemoveInfo: 'tenantSettings.admin.table.remove.info',

  tenantSettingsLanguagesAvailable: "tenantSettings.languages.available",
  tenantSettingsLanguagesAvailableTooltip: "tenantSettings.languages.available.tooltip",
  tenantSettingsLanguagesDefaultSelect: "tenantSettings.languages.default.select",
  tenantSettingsLanguagesDefaultTooltip: "tenantSettings.languages.default.tooltip",
  tenantSettingsLanguagesDefaultModalTitle: "tenantSettings.languages.default.modal.title",
  tenantSettingsLanguagesDefaultModalDescription: "tenantSettings.languages.default.modal.description",

  tenantSettingsCurrencySelect: "tenantSettings.currency.select",
  tenantSettingsCurrencyTooltip: "tenantSettings.currency.tooltip",
  tenantSettingsCurrencyPlaceholder: "tenantSettings.currency.placeholder",

  configConfigure: 'config.configure',
  configSaveSettings: 'config.saveSettings',
  configUpdateSettings: 'config.updateSettings',
  configEnableMethod: 'config.enableMethod',
  configDisableMethod: 'config.disableMethod',
  configDisableFeature: 'config.disableFeature',

  configPurchaseNotification: 'config.purchase.notification',
  configPurchaseDisableTitle: 'config.purchase.disable.title',
  configPurchaseDisableText: 'config.purchase.disable.text',
  configPurchaseFooterTitle: 'config.purchase.footer.title',
  configPurchaseFooterDescription: 'config.purchase.footer.description',
  configPurchaseFooterTooltip: 'config.purchase.footer.tooltip',
  configPurchaseFooterEnableButton: 'config.purchase.footer.enable.button',
  configPurchaseFooterEnableTooltip: 'config.purchase.footer.enable.tooltip',

  configCollectionTitle: 'config.collection.title',
  configCollectionMethod: 'config.collection.method',
  configCollectionDescription: 'config.collection.description',
  configCollectionTooltip: 'config.collection.tooltip',

  configCollectionTokenCardTitle: 'config.collection.tokenCard.title',
  configCollectionTokenCardDescription: 'config.collection.tokenCard.description',
  configCollectionTokenCardTranslationDescription: 'config.collection.tokenCard.translation.description',
  configCollectionTokenCardTranslationDescriptionTooltip: 'config.collection.tokenCard.translation.description.tooltip',

  configCollectionOwnWalletTitle: 'config.collection.ownWallet.title',
  configCollectionOwnWalletDescription: 'config.collection.ownWallet.description',
  configCollectionOwnWalletTranslationDescription: 'config.collection.ownWallet.translation.description',
  configCollectionOwnWalletTranslationDescriptionTooltip: 'config.collection.ownWallet.translation.description.tooltip',

  configCollectionOwnNonSignableWalletTitle: 'config.collection.ownNonSignableWallet.title',
  configCollectionOwnNonSignableWalletDescription: 'config.collection.ownNonSignableWallet.description',
  configCollectionOwnNonSignableWalletTranslationDescription: 'config.collection.ownNonSignableWallet.translation.description',
  configCollectionOwnNonSignableWalletTranslationDescriptionTooltip: 'config.collection.ownNonSignableWallet.translation.description.tooltip',
  configCollectionOwnNonSignableWalletWlmWalletsLabel: 'config.collection.ownNonSignableWallet.wlmWallets.label',
  configCollectionOwnNonSignableWalletWlmWalletsTooltip: 'config.collection.ownNonSignableWallet.wlmWallets.tooltip',

  configCollectionOneTimeWalletTitle: 'config.collection.oneTimeWallet.title',
  configCollectionOneTimeWalletDescription: 'config.collection.oneTimeWallet.description',
  configCollectionOneTimeWalletTranslationDescription: 'config.collection.oneTimeWallet.translation.description',
  configCollectionOneTimeWalletTranslationDescriptionTooltip: 'config.collection.oneTimeWallet.translation.description.tooltip',
  configCollectionOneTimeWalletTranslationDisclaimer: 'config.collection.oneTimeWallet.translation.disclaimer',
  configCollectionOneTimeWalletTranslationDisclaimerTooltip: 'config.collection.oneTimeWallet.translation.disclaimer.tooltip',

  configCollectionDescriptionTitle: 'config.collection.description.title',
  configCollectionDisclaimerTitle: 'config.collection.disclaimer.title',
  configCollectionFeesTitle: 'config.collection.fees.title',
  configCollectionFeesSubtitle: 'config.collection.fees.subtitle',
  configCollectionFeesDescription: 'config.collection.fees.description',
  configCollectionFeesExample: 'config.collection.fees.example',
  configCollectionFeesExampleVariable: 'config.collection.fees.example.variable',
  configCollectionFeesExampleFixed: 'config.collection.fees.example.fixed',
  configCollectionFeesExampleTotal: 'config.collection.fees.example.total',
  configCollectionFeesExampleTotalDescription: 'config.collection.fees.example.total.description',
  configCollectionFeesMethodTitle: 'config.collection.fees.method.title',
  configCollectionFeesMethodDescription: 'config.collection.fees.method.description',
  configCollectionFeesMethodFixed: 'config.collection.fees.method.fixed',
  configCollectionFeesMethodVariable: 'config.collection.fees.method.variable',
  configCollectionFeesCountryTitle: 'config.collection.fees.country.title',
  configCollectionFeesCountryDescription: 'config.collection.fees.country.description',
  configCollectionFeesCountry: 'config.collection.fees.country',
  configCollectionFeesCountryFixed: 'config.collection.fees.country.fixed',
  configCollectionFeesCountryVariable: 'config.collection.fees.country.variable',
  configCollectionFeesCountryDefault: 'config.collection.fees.country.default',
  configCollectionFeesCountryDefaultFixed: 'config.collection.fees.country.default.fixed',
  configCollectionFeesCountryDefaultVariable: 'config.collection.fees.country.default.variable',
  configCollectionFeesCountryButton: 'config.collection.fees.country.button',
  configCollectionFooterDescription: 'config.collection.footer.description',

  configPaymentTitle: 'config.payment.title',
  configPaymentMethod: 'config.payment.method',
  configPaymentDescription: 'config.payment.description',
  configPaymentTooltip: 'config.payment.tooltip',
  configPaymentApiKey: 'config.payment.api.key',
  configPaymentApiSecret: 'config.payment.api.secret',

  configPaymentBankTransferTitle: 'config.payment.bankTransfer.title',
  configPaymentBankTransferDescription: 'config.payment.bankTransfer.description',
  configPaymentBankTransferTranslationDescription: 'config.payment.bankTransfer.translation.description',
  configPaymentBankTransferTranslationBeneficiaryLabel: 'config.payment.bankTransfer.translation.beneficiary.label',
  configPaymentBankTransferBeneficiaryTitle: 'config.payment.bankTransfer.beneficiary.title',
  configPaymentBankTransferBeneficiaryTooltip: 'config.payment.bankTransfer.beneficiary.tooltip',
  configPaymentBankTransferBeneficiaryName: 'config.payment.bankTransfer.beneficiary.name',
  configPaymentBankTransferBeneficiaryNameTooltip: 'config.payment.bankTransfer.beneficiary.name.tooltip',
  configPaymentBankTransferAccountTooltip: 'config.payment.bankTransfer.account.tooltip',
  configPaymentBankTransferAddressTooltip: 'config.payment.bankTransfer.address.tooltip',
  configPaymentBankTransferNameTooltip: 'config.payment.bankTransfer.name.tooltip',
  configPaymentBankTransferSwiftTooltip: 'config.payment.bankTransfer.swift.tooltip',
  configPaymentBankTransferBicTooltip: 'config.payment.bankTransfer.bic.tooltip',
  configPaymentBankTransferIntermediaryDescription: 'config.payment.bankTransfer.intermediary.description',
  configPaymentBankTransferIntermediaryTooltip: 'config.payment.bankTransfer.intermediary.tooltip',
  configPaymentBankTransferIntermediaryTranslationTitle: 'config.payment.bankTransfer.intermediary.translation.title',
  configPaymentBankTransferIntermediaryTranslationTooltip: 'config.payment.bankTransfer.intermediary.translation.tooltip',
  configPaymentBankTransferIdentifiersTitle: 'config.payment.bankTransfer.identifiers.title',
  configPaymentBankTransferIdentifiersDescription: 'config.payment.bankTransfer.identifiers.description',
  configPaymentBankTransferIdentifiersAdd: 'config.payment.bankTransfer.identifiers.add',
  configPaymentBankTransferIdentifiersConfirmation: 'config.payment.bankTransfer.identifiers.confirmation',
  configPaymentBankTransferIdentifiersLabelValue: 'config.payment.bankTransfer.identifiers.label.value',
  configPaymentBankTransferIdentifiersLabelNameDefault: 'config.payment.bankTransfer.identifiers.label.name.default',
  configPaymentBankTransferIdentifiersLabelNameLanguage: 'config.payment.bankTransfer.identifiers.label.name.language',
  configPaymentBankTransferIdentifiersLabelDescriptionDefault: 'config.payment.bankTransfer.identifiers.label.description.default',
  configPaymentBankTransferIdentifiersLabelDescriptionLanguage: 'config.payment.bankTransfer.identifiers.label.description.language',

  configPaymentCreditCardTitle: 'config.payment.creditCard.title',
  configPaymentCreditCardDescription: 'config.payment.creditCard.description',
  configPaymentCreditCardTranslationDescription: 'config.payment.creditCard.translation.description',
  configPaymentCreditCardAccountTitle: 'config.payment.creditCard.account.title',
  configPaymentCreditCardAccountName: 'config.payment.creditCard.account.name',
  configPaymentCreditCardAccountNameDescription: 'config.payment.creditCard.account.name.description',
  configPaymentCreditCardAccountKeyDescription: 'config.payment.creditCard.account.key.description',
  configPaymentCreditCardAccountInvalid: 'config.payment.creditCard.account.invalid',

  configPaymentCryptoCurrencyTitle: 'config.payment.cryptoCurrency.title',
  configPaymentCryptoCurrencyDescription: 'config.payment.cryptoCurrency.description',
  configPaymentCryptoCurrencyTranslationDescription: 'config.payment.cryptoCurrency.translation.description',
  configPaymentCryptoCurrencyAccountTitle: 'config.payment.cryptoCurrency.account.title',
  configPaymentCryptoCurrencyAccountDescription: 'config.payment.cryptoCurrency.account.description',
  configPaymentCryptoCurrencyAccountKeyDescription: 'config.payment.cryptoCurrency.account.key.description',
  configPaymentCryptoCurrencyAccountInvalid: 'config.payment.cryptoCurrency.account.invalid',

  configPaymentDescriptionTitle: 'config.payment.description.title',
  configPaymentTranslationDescriptionTooltip: 'config.payment.translation.description.tooltip',
  configPaymentFooterDescription: 'config.payment.footer.description',

  configPaymentKycTitle: "config.payment.kyc.title",
  configPaymentKycTooltip: "config.payment.kyc.toolip",
  configPaymentKycLabel: "config.payment.kyc.label",

  configPaymentLimitTitle: "config.payment.limit.title",
  configPaymentLimitTooltip: "config.payment.limit.toolip",
  configPaymentLimitLabel: "config.payment.limit.label",

  configPaymentTemplateTitleBank: "config.payment.template.title.bank",
  configPaymentTemplateTitleOther: "config.payment.template.title.other",
  configPaymentTemplateDescription: "config.payment.template.description",
  configPaymentTemplateDescriptionBank: "config.payment.template.description.bank",
  configPaymentTemplateDescriptionOther: "config.payment.template.description.other",
  configPaymentTemplateRequirementsTitle: "config.payment.template.requirements.title",
  configPaymentTemplateRequirementsFirst: "config.payment.template.requirements.first",
  configPaymentTemplateRequirementsSecond: "config.payment.template.requirements.second",
  configPaymentTemplateRequirementsThird: "config.payment.template.requirements.third",
  configPaymentTemplateTest: "config.payment.template.test",

  configFooterMethodEnabledDescription: 'config.footer.method.enabled.description',

  configTranslationsField: 'config.translations.field',
  configTranslationsTitle: 'config.translations.title',
  configTranslationsDescriptionLabelDefault: 'config.translations.description.label.default',
  configTranslationsDescriptionLabelLanguage: 'config.translations.description.label.language',
  configTranslationsDisclaimerLabelDefault: 'config.translations.disclaimer.label.default',
  configTranslationsDisclaimerLabelLanguage: 'config.translations.disclaimer.label.language',
  configTranslationsLanguageLabel: 'config.translations.language.label',
  configTranslationsLanguageButton: 'config.translations.language.button',

  validatorWalletAddressInvalid: 'validator.walletAddress.invalid',
  validatorWalletAddressRequired: 'validator.walletAddress.required',
  validatorKycTiersDescriptionTierRequired: 'validator.kycTiersDescription.tierRequired',
  validatorKycTiersDescriptionProviderRequired: 'validator.kycTiersDescription.providerRequired',
  validatorKycTiersDescriptionUniqueTierNumber: 'validator.kycTiersDescription.uniqueTierNumber',
  validatorKycTiersDescriptionSingleProvider: 'validator.kycTiersDescription.singleProvider',
  validatorIsFeaturedAssetSingleAsset: 'validator.isFeaturedAsset.singleAsset',
  validatorIsFeaturedAssetRequired: 'validator.isFeaturedAsset.required',
  validatorAssetsRequired: 'validator.assets.required',
  validatorAssetsUniqueAssetIds: 'validator.assets.uniqueAssetIds',
  validatorRedemption: "validator.redemption",
  validatorRedemptionUniqueMethodIds: 'validator.redemption.uniqueMethodIds',
  validatorRedemptionLimits: 'validator.redemption.limits',
  validatorRedemptionLimitsDuplicate: 'validator.redemption.limits.duplicate',
  validatorRedemptionLimitsScale: 'validator.redemption.limits.scale',
  validatorRedemptionMaxAmount: 'validator.redemption.maxAmount',
  validatorRedemptionFeesMissing: "validator.redemption.fees.missing",
  validatorRedemptionFeesInvalid: "validator.redemption.fees.invalid",
  validatorTranslationLanguageDefault: "validator.translation.language.default",
  validatorMessage: 'validator.message',
  validatorRelatedConflict: 'validator.related.conflict',
  validatorRelatedMissing: 'validator.related.missing',
  validatorOneOfMessage: 'validator.oneOf.message',
  validatorMaxLengthExceeded: 'validator.maxLengthExceeded',

  customGoldPriceTitle: 'custom.goldPrice.title',
  customGoldPriceBuy: 'custom.goldPrice.buy',
  customGoldPriceSell: 'custom.goldPrice.sell',
  customCalculatorBuy: 'custom.calculator.buy',
  customCalculatorSell: 'custom.calculator.sell',
  customCalculatorWeight: 'custom.calculator.weight',
  customCalculatorPrice: 'custom.calculator.price',
  customInfoHow: 'custom.info.how',
  customInfoAdvantages: 'custom.info.advantages',
  customInfoFaq: 'custom.info.faq',

  languagesEn: "languages.en",
  languagesDe: "languages.de",
  languagesEs: "languages.es",
  languagesFr: "languages.fr",

  langAF: 'lang.AF',
  langAX: 'lang.AX',
  langAL: 'lang.AL',
  langDZ: 'lang.DZ',
  langAS: 'lang.AS',
  langAD: 'lang.AD',
  langAO: 'lang.AO',
  langAI: 'lang.AI',
  langAQ: 'lang.AQ',
  langAG: 'lang.AG',
  langAR: 'lang.AR',
  langAM: 'lang.AM',
  langAW: 'lang.AW',
  langAU: 'lang.AU',
  langAT: 'lang.AT',
  langAZ: 'lang.AZ',
  langBS: 'lang.BS',
  langBH: 'lang.BH',
  langBD: 'lang.BD',
  langBB: 'lang.BB',
  langBY: 'lang.BY',
  langBE: 'lang.BE',
  langBZ: 'lang.BZ',
  langBJ: 'lang.BJ',
  langBM: 'lang.BM',
  langBT: 'lang.BT',
  langBO: 'lang.BO',
  langBA: 'lang.BA',
  langBW: 'lang.BW',
  langBV: 'lang.BV',
  langBR: 'lang.BR',
  langIO: 'lang.IO',
  langBN: 'lang.BN',
  langBG: 'lang.BG',
  langBF: 'lang.BF',
  langBI: 'lang.BI',
  langKH: 'lang.KH',
  langCM: 'lang.CM',
  langCA: 'lang.CA',
  langCV: 'lang.CV',
  langKY: 'lang.KY',
  langCF: 'lang.CF',
  langTD: 'lang.TD',
  langCL: 'lang.CL',
  langCN: 'lang.CN',
  langCX: 'lang.CX',
  langCC: 'lang.CC',
  langCO: 'lang.CO',
  langKM: 'lang.KM',
  langCG: 'lang.CG',
  langCD: 'lang.CD',
  langCK: 'lang.CK',
  langCR: 'lang.CR',
  langCI: 'lang.CI',
  langHR: 'lang.HR',
  langCU: 'lang.CU',
  langCY: 'lang.CY',
  langCZ: 'lang.CZ',
  langDK: 'lang.DK',
  langDJ: 'lang.DJ',
  langDM: 'lang.DM',
  langDO: 'lang.DO',
  langEC: 'lang.EC',
  langEG: 'lang.EG',
  langSV: 'lang.SV',
  langGQ: 'lang.GQ',
  langER: 'lang.ER',
  langEE: 'lang.EE',
  langET: 'lang.ET',
  langFK: 'lang.FK',
  langFO: 'lang.FO',
  langFJ: 'lang.FJ',
  langFI: 'lang.FI',
  langFR: 'lang.FR',
  langGF: 'lang.GF',
  langPF: 'lang.PF',
  langTF: 'lang.TF',
  langGA: 'lang.GA',
  langGM: 'lang.GM',
  langGE: 'lang.GE',
  langDE: 'lang.DE',
  langGH: 'lang.GH',
  langGI: 'lang.GI',
  langGR: 'lang.GR',
  langGL: 'lang.GL',
  langGD: 'lang.GD',
  langGP: 'lang.GP',
  langGU: 'lang.GU',
  langGT: 'lang.GT',
  langGG: 'lang.GG',
  langGN: 'lang.GN',
  langGW: 'lang.GW',
  langGY: 'lang.GY',
  langHT: 'lang.HT',
  langHM: 'lang.HM',
  langVA: 'lang.VA',
  langHN: 'lang.HN',
  langHK: 'lang.HK',
  langHU: 'lang.HU',
  langIS: 'lang.IS',
  langIN: 'lang.IN',
  langID: 'lang.ID',
  langIR: 'lang.IR',
  langIQ: 'lang.IQ',
  langIE: 'lang.IE',
  langIM: 'lang.IM',
  langIL: 'lang.IL',
  langIT: 'lang.IT',
  langJM: 'lang.JM',
  langJP: 'lang.JP',
  langJE: 'lang.JE',
  langJO: 'lang.JO',
  langKZ: 'lang.KZ',
  langKE: 'lang.KE',
  langKI: 'lang.KI',
  langKR: 'lang.KR',
  langKP: 'lang.KP',
  langKW: 'lang.KW',
  langKG: 'lang.KG',
  langLA: 'lang.LA',
  langLV: 'lang.LV',
  langLB: 'lang.LB',
  langLS: 'lang.LS',
  langLR: 'lang.LR',
  langLY: 'lang.LY',
  langLI: 'lang.LI',
  langLT: 'lang.LT',
  langLU: 'lang.LU',
  langMO: 'lang.MO',
  langMK: 'lang.MK',
  langMG: 'lang.MG',
  langMW: 'lang.MW',
  langMY: 'lang.MY',
  langMV: 'lang.MV',
  langML: 'lang.ML',
  langMT: 'lang.MT',
  langMH: 'lang.MH',
  langMQ: 'lang.MQ',
  langMR: 'lang.MR',
  langMU: 'lang.MU',
  langYT: 'lang.YT',
  langMX: 'lang.MX',
  langFM: 'lang.FM',
  langMD: 'lang.MD',
  langMC: 'lang.MC',
  langMN: 'lang.MN',
  langME: 'lang.ME',
  langMS: 'lang.MS',
  langMA: 'lang.MA',
  langMZ: 'lang.MZ',
  langMM: 'lang.MM',
  langNA: 'lang.NA',
  langNR: 'lang.NR',
  langNP: 'lang.NP',
  langNL: 'lang.NL',
  langAN: 'lang.AN',
  langNC: 'lang.NC',
  langNZ: 'lang.NZ',
  langNI: 'lang.NI',
  langNE: 'lang.NE',
  langNG: 'lang.NG',
  langNU: 'lang.NU',
  langNF: 'lang.NF',
  langMP: 'lang.MP',
  langNO: 'lang.NO',
  langOM: 'lang.OM',
  langPK: 'lang.PK',
  langPW: 'lang.PW',
  langPS: 'lang.PS',
  langPA: 'lang.PA',
  langPG: 'lang.PG',
  langPY: 'lang.PY',
  langPE: 'lang.PE',
  langPH: 'lang.PH',
  langPN: 'lang.PN',
  langPL: 'lang.PL',
  langPT: 'lang.PT',
  langPR: 'lang.PR',
  langQA: 'lang.QA',
  langRE: 'lang.RE',
  langRO: 'lang.RO',
  langRU: 'lang.RU',
  langRW: 'lang.RW',
  langBL: 'lang.BL',
  langSH: 'lang.SH',
  langKN: 'lang.KN',
  langLC: 'lang.LC',
  langMF: 'lang.MF',
  langPM: 'lang.PM',
  langVC: 'lang.VC',
  langWS: 'lang.WS',
  langSM: 'lang.SM',
  langST: 'lang.ST',
  langSA: 'lang.SA',
  langSN: 'lang.SN',
  langRS: 'lang.RS',
  langSC: 'lang.SC',
  langSL: 'lang.SL',
  langSG: 'lang.SG',
  langSK: 'lang.SK',
  langSI: 'lang.SI',
  langSB: 'lang.SB',
  langSO: 'lang.SO',
  langZA: 'lang.ZA',
  langGS: 'lang.GS',
  langES: 'lang.ES',
  langLK: 'lang.LK',
  langSD: 'lang.SD',
  langSR: 'lang.SR',
  langSJ: 'lang.SJ',
  langSZ: 'lang.SZ',
  langSE: 'lang.SE',
  langCH: 'lang.CH',
  langSY: 'lang.SY',
  langTW: 'lang.TW',
  langTJ: 'lang.TJ',
  langTZ: 'lang.TZ',
  langTH: 'lang.TH',
  langTL: 'lang.TL',
  langTG: 'lang.TG',
  langTK: 'lang.TK',
  langTO: 'lang.TO',
  langTT: 'lang.TT',
  langTN: 'lang.TN',
  langTR: 'lang.TR',
  langTM: 'lang.TM',
  langTC: 'lang.TC',
  langTV: 'lang.TV',
  langUG: 'lang.UG',
  langUA: 'lang.UA',
  langAE: 'lang.AE',
  langGB: 'lang.GB',
  langUS: 'lang.US',
  langUM: 'lang.UM',
  langUY: 'lang.UY',
  langUZ: 'lang.UZ',
  langVU: 'lang.VU',
  langVE: 'lang.VE',
  langVN: 'lang.VN',
  langVG: 'lang.VG',
  langVI: 'lang.VI',
  langWF: 'lang.WF',
  langEH: 'lang.EH',
  langYE: 'lang.YE',
  langZM: 'lang.ZM',
  langZW: 'lang.ZW',
};

export { i18nextKeys };
