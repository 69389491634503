import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import BurgerMenu from 'Components/shared/BurgerMenu';
import LoadingSpinner from "Components/shared/LoadingSpinner";
import { LangContext } from 'States/lang/langState';
import { UiContext } from "States/ui/uiState";
import { ROUTES } from "Router/Routes";
import Urls from 'Utils/urls';

const instance = axios.create();
instance.defaults.headers.common = {};

const InfoPage = () => {
  const [html, setHtml] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { name } = useParams();
  const {
    breakpoints: { md, lg },
  } = useContext(UiContext);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    const loadTemplate = async (language, fileName) => {
      try {
        const { data } = await instance.get(
          `${Urls.get()?.blob}/info-pages/${language}/${fileName}.html`
        );
        if (data) {
          setHtml(data);
        } else {
          history.push(ROUTES.base);
        }
      } catch (error) {
        history.push(ROUTES.base);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    loadTemplate(lang, name);
  }, [lang, name]);

  return !loading && html ? (
    <div className="revertPageContainer">
      {!lg ? (
        <div
          className="fixed z-40"
          style={{
            top: '15px',
            left: '15px',
          }}
        >
          <BurgerMenu md={md} />
        </div>
      ) : null}

      <div
        style={{ minHeight: '100%' }}
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    </div>
  ) : (
    <div className = "w-full h-full flex items-center justify-center">
      <LoadingSpinner />
    </div >
  );
}

export default InfoPage;
