import { useContext } from 'react';
import {
  defaultLocale,
  websiteLangToLocale,
} from 'Lang/i18nextConfig';
import { LangContext } from 'States/lang/langState';

const formatNumber = (number, maxDecimalPlaces = 2, locale = defaultLocale, minDecimalPlaces) => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maxDecimalPlaces,
    ...minDecimalPlaces && { minimumFractionDigits: minDecimalPlaces }
  }).format(number);
};

const useFormatNumber = () => {
  const { lang } = useContext(LangContext);
  const locale = websiteLangToLocale[lang];
  return (number = 0, maxDecimalPlaces, minDecimalPlaces) => {
    const numberScale = String(number).split(".")[1]?.length;
    return formatNumber(
      number,
      maxDecimalPlaces || numberScale || 2,
      locale,
      minDecimalPlaces
    );
  };
};

const formatBigNumberString = (numberStr, locale = defaultLocale) => {
  if (typeof numberStr === "number") {
    numberStr = String(numberStr);
  }
  const [integer, decimal] = numberStr.split(".");
  const trimmedDecimal = decimal && decimal.replace(/(^\d*[1-9])0+$/, '$1'); // Trim trailing decimal zeros
  const formattedInteger = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 1,
  }).format(integer);
  const formattedNumberStr = trimmedDecimal && Number(trimmedDecimal) !== 0
    ? formattedInteger.slice(0, -1) + trimmedDecimal
    : formattedInteger.slice(0, -2);
  return formattedNumberStr;
};

const useFormatBigNumberString = () => {
  const { lang } = useContext(LangContext);
  const locale = websiteLangToLocale[lang];
  return (numberStr = "0") => {
    return formatBigNumberString(numberStr, locale);
  };
};

export {
  useFormatNumber,
  formatNumber,
  useFormatBigNumberString
};
