import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import TENANT_FEATURE from 'Enums/TenantFeature';

import { ConfigContext } from 'States/config/configState';
import ErrorPage from 'Components/shared/ErrorPage/ErrorPage';
import Imprint from 'Components/Imprint';
import Login from 'Components/Login/Login';
import Welcome from 'Components/Welcome/Welcome';
import Purchase from 'Features/Purchase';
import FeaturedTokenPurchasePage from 'Features/Purchase/FeaturedTokenPurchasePage';
import InfoPage from 'Features/Custom/InfoPage';
import NotFound from './NotFound';
import { ROUTES } from './Routes';

const UnauthenticatedApp = ({
  features,
  featuredAsset = null,
  restrictedMode = false
}) => {
  const {
    config: { checkoutSettings }
  } = useContext(ConfigContext);

  return (
    <Switch>
      <Route
        path={ROUTES.error}
        component={ErrorPage}
      />

      {!restrictedMode && !checkoutSettings.hideShop
        && isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
        && (
          <Route
            path={ROUTES.shop}
            render={(props) => <Purchase {...props} />}
          />
        )
      }

      {!!featuredAsset && (
        <Route
          path={ROUTES.featuredAssetBase}
          render={(props) =>
            <FeaturedTokenPurchasePage
              featuredAsset={featuredAsset}
              {...props}
            />
          }
        />
      )}

      <Route path={ROUTES.login}>
        <Login />
      </Route>

      <Route path={["/register", ROUTES.welcome]}>
        <Welcome />
      </Route>

      <Route
        exact
        path={ROUTES.base}
        render={(props) => {
          if (!!featuredAsset) {
            return (
              <FeaturedTokenPurchasePage
                featuredAsset={featuredAsset}
                {...props}
              />
            );
          }
          if (
            !restrictedMode
            && isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
          ) {
            return <Purchase {...props} />;
          }

          return <Welcome />;
        }}
      />

      <Route
        path={ROUTES.imprint}
        component={Imprint}
      />
      <Route
        path={ROUTES.custom.info}
        component={InfoPage}
        exact
      />
      <Route
        render={(props) =>
          <NotFound
            redirectPath={
              restrictedMode
                ? ROUTES.welcome
                : undefined
            }
            {...props}
          />
        }
      />
    </Switch>
  );
};

export default UnauthenticatedApp;
