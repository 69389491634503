import React, { useEffect, useState } from "react";
import Big from "big.js";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Toggle from "Components/shared/Toggle";
import Text from "Components/shared/Text";
import { DropdownField } from "Components/shared/formElements";
import { NumberInput } from "Components/shared/formElements";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";

const PriceCalculator = ({ prices = [] }) => {
  const [isSelling, setIsSelling] = useState(false);
  const [isByWeight, setByWeight] = useState(true);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [weight, setWeight] = useState(1);
  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    if (!prices?.length) {
      return;
    }
    const updatedPrice = prices.find(price => 
      price.currency === (currentPrice?.currency || "CHF"));
    setCurrentPrice(updatedPrice);
    if (!currentPrice) {
      setTotalPrice(updatedPrice?.sell)
    }
  }, [prices]);

  useEffect(() => {
    if (!currentPrice || !totalPrice) {
      return;
    }
    recalculate();
  }, [isSelling, currentPrice]);

  const recalculate = () => {
    if (isByWeight) {
      onWeightChange(weight);
    } else {
      onTotalPriceChange(totalPrice);
    }
  };

  const onWeightChange = (newWeight) => {
    if (!newWeight) {
      return;
    }
    const newTotalPrice = Big(currentPrice[isSelling ? "buy" : "sell"])
      .times(newWeight)
      .round(2, isSelling ? 0 : 3)
      .toNumber();
    setTotalPrice(newTotalPrice);
    setWeight(newWeight);
    setByWeight(true);
  };

  const onTotalPriceChange = (newTotalPrice) => {
    if (!newTotalPrice) {
      return;
    }
    const newWeight = Big(newTotalPrice)
      .div(currentPrice[isSelling ? "buy" : "sell"])
      .round(3, isSelling ? 0 : 3)
      .toNumber();
    setWeight(newWeight);
    setTotalPrice(newTotalPrice);
    setByWeight(false);
  };

  return (
    <div
      className="py-12 px-8 m-16 rounded border"
      style={{
        backgroundColor: "#0c0c0c",
        borderColor: "#C9A73F",
        boxShadow: "0px 0px 3px #C9A73F, 0px 0px 3px #C9A73F"
      }}
      data-qa="gold-calculator"
    >
      {currentPrice ? (
        <>
          <div className="flex justify-center gap-10 mb-10">
            <Text
              textStyle="p2"
              style={{ color: "#C9A73F" }}
              dataQa="gold-calculator-buy"
            >
              {i18nextTranslate(i18nextKeys.customCalculatorBuy)}
            </Text>
            <Toggle
              toggled={isSelling}
              onToggle={() => setIsSelling(!isSelling)}
              dataQa={"gold-calculator-mode"}
              sameBackgroundColor
            />
            <Text
              textStyle="p2"
              style={{ color: "#C9A73F" }}
              dataQa="gold-calculator-sell"
            >
              {i18nextTranslate(i18nextKeys.customCalculatorSell)}
            </Text>
          </div>
          <div className="text-base flex flex-col gap-10">
            <DropdownField
              label={i18nextTranslate(i18nextKeys.commonCurrency)}
              labelStyle={{ color: "#C9A73F" }}
              options={prices}
              input={{
                value: currentPrice,
                onChange: (value, option) => setCurrentPrice(option)
              }}
              labelKey="currency"
              valueKey="currency"
              valueContainerStyle={{
                padding: "2px 12px"
              }}
              textAlign="right"
              dataQa="gold-calculator-currency"
            />
            <NumberInput
              value={weight}
              scale={3}
              labelText={i18nextTranslate(i18nextKeys.customCalculatorWeight)}
              labelStyle={{ color: "#C9A73F" }}
              innerLabelText="g"
              style={{ width: "100%" }}
              onChange={onWeightChange}
              dataQa="gold-calculator-weight"
              showInnerLabel
              textRight
              staticInnerLabel
              decimal
            />
            <NumberInput
              value={totalPrice}
              scale={2}
              labelText={i18nextTranslate(i18nextKeys.customCalculatorPrice)}
              labelStyle={{ color: "#C9A73F" }}
              innerLabelText={currentPrice.currency}
              style={{ width: "100%" }}
              onChange={onTotalPriceChange}
              dataQa="gold-calculator-price"
              showInnerLabel
              textRight
              staticInnerLabel
              decimal
            />
          </div>
        </>
      ) : <LoadingSpinner size="h-32 w-32 mx-auto" dataQa="price-calculator-loading" />}
    </div>
  )
};

export default PriceCalculator;
