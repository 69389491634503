import React, { useContext, useEffect, useRef, useState } from "react";
import Big from "big.js";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { UiContext } from "States/ui/uiState";
import { bigToRoundedDownString } from "Utils/utils";
import { Button } from "../buttons";
import { MinusSymbol, PlusSymbol, TrashCanSymbol } from "../symbols";
import Text from "../Text";
import Error from "./Error";
import "./NumberInput.css";

const INNER_LABEL_SHIFT = {
  initial: 15,
  digit: 10,
  delimiter: 3
}

const NumberInput = ({
  onChange,
  value,
  name,
  dataQa,
  className = "",
  style = {},
  max,
  min = "0",
  step = "0",
  scale = 0,
  labelText = "",
  labelStyle = {},
  innerLabelText = "",
  showInnerLabel = false,
  staticInnerLabel = false,
  error = false,
  invalid = false,
  touched = false,
  showOwnErrors = false,
  decimal = false,
  disabled = false,
  readOnly = false,
  removable = false,
  required = false,
  textRight = false,
  remove = () => { },
  onBlur = () => { },
  setValidity = () => { },
}) => {
  const [localValueStr, setLocalValueStr] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const innerLabelRef = useRef(null);

  const {
    breakpoints: { xxl },
  } = useContext(UiContext);

  const moveInnerLabel = (newValue) => {
    if (!showInnerLabel || staticInnerLabel) {
      return;
    }
    const newDigits = newValue.length;
    const currentPosition = innerLabelRef.current.offsetLeft;
    const requiredPosition =
      newValue.includes(".")
        ? INNER_LABEL_SHIFT.initial + (newDigits - 1) * INNER_LABEL_SHIFT.digit + INNER_LABEL_SHIFT.delimiter
        : INNER_LABEL_SHIFT.initial + newDigits * INNER_LABEL_SHIFT.digit
    if (currentPosition === requiredPosition) {
      return;
    }
    requestAnimationFrame(() => {
      innerLabelRef.current.style.left = `${requiredPosition}px`;
    });
  };

  const validate = (newValueAsNumber, newValue) => {
    if (!showOwnErrors) {
      return;
    }
    const isInvalidQuantity = Number(min) > newValueAsNumber
      || newValue === ""
      || (newValue === "0" && newValueAsNumber !== Number(min));
    if (isInvalidQuantity) {
      setErrorMessage(i18nextTranslate(i18nextKeys.commonErrorInvalidQuantity));
      setValidity(false);
      return;
    }
    const isGreaterThanMaxNum = max ? Big(newValue).gt(max) : false;
    if (isGreaterThanMaxNum) {
      setErrorMessage(i18nextTranslateDynamically(i18nextKeys.addToCartMaxQuantity, { maxAmount: max }));
      setValidity(false)
    } else {
      setErrorMessage(null);
      setValidity(true)
    }
  };

  useEffect(() => {
    if (
      value === undefined ||
      value === null || // TODO: temporary condition until cart becomes a form
      value === ""
    ) {
      return;
    }
    if (
      localValueStr === undefined ||
      localValueStr !== String(value)
    ) {
      const rounded = bigToRoundedDownString(Big(value), scale);
      setLocalValueStr(rounded);
      moveInnerLabel(rounded);
      if (showOwnErrors && errorMessage) {
        validate(value, String(value));
      }
    }
  }, [value]);

  useEffect(() => {
    if (showInnerLabel && localValueStr !== undefined) {
      moveInnerLabel(localValueStr);
    }
  }, [showInnerLabel]);

  const onChangeFormattedField = ({
    target: { value: newValue }
  }) => {
    newValue = newValue.replaceAll(/[^0-9.]/g, ''); // Leave only digits and dots
    if (!newValue.length && !localValueStr?.length) {
      return;
    }
    newValue = newValue.replace(/^0{2,}$/, '0'); // Squash zeros
    newValue = newValue.replace(/^(0+)([1-9]+)/, '$2'); // Trim leading zeros
    newValue = newValue.replace(/^\./, '0.'); // Add leading zero for decimals
    let firstDelimiter = decimal; // Leave either no dot delimiters or only the first one
    newValue = newValue.replace(/\.\d*/g, (match) => {
      if (firstDelimiter) {
        firstDelimiter = false;
        return match;
      }
      return '';
    });
    if (Number.isInteger(scale) && scale > 0) {
      const scaleRegex = new RegExp(`(\\.\\d{${scale}})\\d+`);
      newValue = newValue.replace(scaleRegex, '$1'); // Trim to scale
    }

    if (newValue === localValueStr) {
      return;
    }

    setLocalValueStr(newValue);
    if (showInnerLabel && !staticInnerLabel) {
      moveInnerLabel(newValue);
    }
    if (newValue && value && Big(newValue).eq(value)) {
      return;
    }

    const newParsedValue = parseFloat(newValue);
    const newValueAsNumber = typeof newParsedValue === "number" && !Number.isNaN(newParsedValue)
      ? newParsedValue
      : null;

    validate(newValueAsNumber, newValue);

    onChange(
      scale > 16 && newValue.length
        ? newValue
        : newValueAsNumber
    );
  };

  const onBlurFormattedField = () => {
    let trimmed = localValueStr.replace(/(\.\d*[1-9])0+$/, '$1'); // Trim trailing decimal zeros
    trimmed = trimmed.replace(/\.0*$/, ''); // Trim empty decimal
    setLocalValueStr(trimmed);
    if (showInnerLabel && !staticInnerLabel) {
      moveInnerLabel(trimmed);
    }
    onBlur();
  };

  const onStep = (newBigValue) => {
    const decimals = scale || step.split(".")[1]?.length || 0;
    const rounded = bigToRoundedDownString(newBigValue, decimals);
    setLocalValueStr(rounded);
    if (showInnerLabel && !staticInnerLabel) {
      moveInnerLabel(rounded);
    }
    onChange(rounded);
  };

  const stepDown = () => {
    onStep(value ? Big(value).minus(step) : Big(min));
  };

  const stepUp = () => {
    onStep(value ? Big(value).plus(step) : Big(max));
  };

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      {labelText ? (
        <Text textStyle="h3" style={labelStyle}>
          <label
            htmlFor={dataQa}
            data-qa={`${dataQa}-label`}
          >
            {labelText}{required ? " *" : null}
          </label>
        </Text>
      ) : null}
      <div className="flex flex-col gap-2 flex-none">
        <div className="flex">
          <div className="flex flex-grow min-w-0 relative">
            <input
              id={dataQa}
              name={name}
              type="text"
              inputMode="numeric"
              value={localValueStr || ""}
              className={`flex-grow inputField inputFieldHideArrows bg-9 focus:bg-9-10 color-10 ${
                touched && (invalid || errorMessage)
                  ? 'bg-lightRed'
                  : disabled
                    ? 'bg-9--10'
                    : ''
                } focus:outline-none ${
                  removable ? "rounded-l" : "rounded"
                } border-5_25 appearance-none ${
                  textRight ? "text-right" : "text-left"
                } truncate ${className}`}
              style={{
                height: xxl ? '38px' : '35px',
                padding: '8px 10px',
                paddingRight: staticInnerLabel ? '45px' : '10px', // TODO: ugly, needs a flexible solution
                ...style
              }}
              onChange={onChangeFormattedField}
              onBlur={onBlurFormattedField}
              onPaste={onChangeFormattedField}
              onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
              disabled={disabled}
              readOnly={readOnly}
              data-qa={`${dataQa}-input`}
            />
            {showInnerLabel && (
              <label
                htmlFor={dataQa}
                ref={innerLabelRef}
                className="absolute color-grey flex items-center"
                data-qa={`${dataQa}-input-text`}
                style={{
                  height: xxl ? '38px' : '35px',
                  left: staticInnerLabel
                    ? 'auto'
                    : `${INNER_LABEL_SHIFT.initial + INNER_LABEL_SHIFT.digit}px`,
                  right: '10px',
                  padding: '8px 0'
                }}
              >
                <span className="truncate">
                  {innerLabelText}
                </span>
              </label>
            )}
          </div>
          {removable && (
            <button
              type="button"
              onClick={remove}
              className="flex justify-center items-center rounded-r bg-3-2"
              style={{ width: xxl ? "40px" : "38px" }}
            >
              <TrashCanSymbol
                size={xxl ? "16" : "14"}
                className="color-6"
              />
            </button>
          )}
          {!!Number(step) && (
            <>
              <Button
                onClick={stepDown}
                icon={MinusSymbol}
                disabled={disabled || (!!value && (Big(value).minus(step).lt(min)))}
                className="ml-4"
                secondary
                square
              />
              <Button
                onClick={stepUp}
                icon={PlusSymbol}
                disabled={disabled || (!!value && Big(value).plus(step).gt(max))}
                className="ml-4"
                secondary
                square
              />
            </>
          )}
        </div>
        {showOwnErrors && errorMessage && (
          <Text
            textStyle="h3"
            color="color-red"
            dataQa={`${dataQa}-input-error`}
          >
            {errorMessage}
          </Text>
        )}
        {!showOwnErrors && touched && error && (
          <Error
            error={error}
            dataQa={`${dataQa}-input-error`}
          />
        )}
      </div>
    </div>
  );
};

export default NumberInput;
