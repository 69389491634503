import React, { useContext } from 'react';
import { generatePath, Switch, Redirect, Route } from 'react-router-dom';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import { TENANT_FEATURE, USER_STATUS } from 'Enums';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import ErrorPage from 'Components/shared/ErrorPage/ErrorPage';
import NotFound from './NotFound';
import Account from 'Features/Account';
import KYCTiers from 'Features/KYC/KycTiers';
import Cart from 'Features/Cart';
import Checkout from 'Features/Checkout';
import Purchase from 'Features/Purchase';
import FeaturedTokenPurchasePage from 'Features/Purchase/FeaturedTokenPurchasePage';
import Redeem from 'Features/Redeem';
import KycManagementOverview from 'Features/admin/KycManagement/KycManagementOverview';
import KycManagementDetails from 'Features/admin/KycManagement/Details/KycManagementDetails';
import PurchaseManagementOverview from 'Features/admin/PurchaseManagement/PurchaseManagementOverview';
import OrderDetails from 'Features/admin/PurchaseManagement/Details/OrderDetails';
import RedeemManagementOverview from 'Features/admin/RedeemManagement/RedeemManagementOverview';
import RedemptionDetails from 'Features/admin/RedeemManagement/Details/RedemptionDetails';
import ConfigManagement from 'Features/admin/ConfigManagement';
import JsonConfigManagement from 'Features/admin/JsonConfigManagement';
import OrderHistory from 'Features/Account/OrderHistory';
import RedeemHistory from 'Features/Account/RedeemHistory';
import Logout from 'Components/Logout/Logout';
import EmailConfirmation from 'Features/Signup/EmailConfirmation';
import AccountBlocked from 'Features/Signup/AccountBlocked';
import AccountVerification from 'Features/Signup/AccountVerification';
import Signup from 'Features/Signup/Signup';
import InfoPage from 'Features/Custom/InfoPage';
import About from 'Components/About/About';
import Imprint from 'Components/Imprint';
import Welcome from 'Components/Welcome/Welcome';
import { ROUTES } from './Routes';

const AuthenticatedApp = ({
  features,
  restrictedMode = false,
  allowedRedemption = null,
  featuredAsset = null
}) => {
  const { isAdmin, status } = useContext(AuthContext);
  const {
    config: { checkoutSettings }
  } = useContext(ConfigContext);

  const isCheckoutAllowed = status === USER_STATUS.Approved &&
    (isTenantFeatureAvailable(features, TENANT_FEATURE.purchase) ||
    isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset)) &&
    (!restrictedMode || !!featuredAsset);
  const isRedemptionAllowed = isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) &&
    (!restrictedMode || !!allowedRedemption)

  return (
    <Switch>
      {/* Routes that require authentication  */}
      {status === USER_STATUS.Approved && (
        <Route
          path={ROUTES.cart}
          render={(props) => <Cart {...props} />}
        />
      )}
      {(isAdmin || (!restrictedMode && !checkoutSettings.hideShop))
        && isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
        && (
          <Route
            path={ROUTES.shop}
            render={(props) => <Purchase {...props} />}
          />
        )
      }
      {!!featuredAsset && (
        <Route
          path={ROUTES.featuredAssetBase}
          render={(props) =>
            <FeaturedTokenPurchasePage
              featuredAsset={featuredAsset}
              {...props}
            />
          }
        />
      )}
      <Route
        exact
        path={ROUTES.base}
        render={(props) => {
          if (!!featuredAsset) {
            return (
              <FeaturedTokenPurchasePage
                featuredAsset={featuredAsset}
                {...props}
              />
            );
          }
          if (
            !restrictedMode
            && isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
          ) {
            return <Purchase {...props} />;
          }
          if (restrictedMode && isRedemptionAllowed) {
            return (
              <Redirect
                to={generatePath(
                  ROUTES.redemptionForm,
                  { id: allowedRedemption }
                )}
              />
            );
          }
          return <Welcome />;
        }}
      />
      {isTenantFeatureAvailable(features, TENANT_FEATURE.kyc) && (
        <Route
          path={ROUTES.kyc}
          render={(props) => <KYCTiers {...props} />}
        />
      )}
      {isRedemptionAllowed && (
        <Route
          path={ROUTES.redemption}
          render={(props) => <Redeem {...props} />}
        />
      )}
      {isCheckoutAllowed && (
        <Route
          path={ROUTES.checkout}
          render={(props) => <Checkout {...props} />}
        />
      )}
      {status === USER_STATUS.Approved && (
        <Route
          path={ROUTES.account}
          render={(props) => <Account {...props} />}
        />
      )}
      {status === USER_STATUS.Approved && (
        <Route
          path={ROUTES.orderDetails}
          render={(props) => (
            <OrderDetails {...props} />
          )}
        />
      )}
      {status === USER_STATUS.Approved && (
        <Route
          path={ROUTES.orders}
          render={(props) => <OrderHistory {...props} />}
        />
      )}

      {status === USER_STATUS.Approved && (
        <Route
          path="/history/redemptions/:id"
          render={(props) => (
            <RedemptionDetails {...props} />
          )}
        />
      )}
      {status === USER_STATUS.Approved && (
        <Route
          path={ROUTES.redemptions}
          render={(props) => <RedeemHistory {...props} />}
        />
      )}
      {/* Admin routes */}
      {isAdmin && isTenantFeatureAvailable(features, TENANT_FEATURE.kyc) && (
        <Route
          exact
          path="/admin/kyc"
          render={(props) => <KycManagementOverview {...props} />}
        />
      )}
      {isAdmin && isTenantFeatureAvailable(features, TENANT_FEATURE.kyc) && (
        <Route
          path="/admin/kyc/:id"
          render={(props) => <KycManagementDetails {...props} />}
        />
      )}
      {isAdmin && (
        <Route
          path={ROUTES.admin.orderDetails}
          render={(props) => <OrderDetails isAdmin {...props} />}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path={ROUTES.admin.orders}
          render={(props) => <PurchaseManagementOverview {...props} />}
        />
      )}
      {isAdmin && isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) && (
        <Route
          exact
          path={ROUTES.admin.redemptionDetails}
          render={(props) => <RedemptionDetails isAdmin {...props} />}
        />
      )}
      {isAdmin && isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) && (
        <Route
          exact
          path={ROUTES.admin.redemptions}
          render={(props) => <RedeemManagementOverview {...props} />}
        />
      )}
      {isAdmin && (
        <Route
          path={ROUTES.admin.config.section}
          render={(props) => <ConfigManagement {...props} />}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path={ROUTES.admin.config.json}
          render={(props) => <JsonConfigManagement {...props} />}
        />
      )}
      {/* End Admin routes */}
      <Route
        path={ROUTES.signup}
        component={Signup}
      />
      <Route
        path={ROUTES.confirmEmail}
        component={EmailConfirmation}
      />
      <Route
        path={ROUTES.verifyProfile}
        component={AccountVerification}
      />
      <Route
        path={ROUTES.blocked}
        component={AccountBlocked}
      />
      <Route
        path={ROUTES.welcome}
        component={Welcome}
      />
      <Route
        path={ROUTES.about}
        component={About}
      />
      <Route
        path={ROUTES.imprint}
        component={Imprint}
      />
      <Route
        path={ROUTES.logout}
        component={Logout}
      />
      <Route
        path={ROUTES.error}
        component={ErrorPage}
      />

      {restrictedMode && isRedemptionAllowed && (
        <Redirect
          to={generatePath(
            ROUTES.redemptionForm,
            { id: allowedRedemption }
          )}
        />
      )}
      {restrictedMode && !!featuredAsset && (
        <Redirect
          to={generatePath(
            ROUTES.featuredAsset,
            { id: featuredAsset.Id }
          )}
        />
      )}
      <Route
        path={ROUTES.custom.info}
        component={InfoPage}
        exact
      />
      <Route
        render={(props) =>
          <NotFound
            redirectPath={
              restrictedMode
                ? ROUTES.welcome
                : undefined
            }
            {...props}
          />
        }
      />
    </Switch>
  );
};

export default AuthenticatedApp;
