import React from 'react';

const Toggle = ({
  toggled,
  onToggle = () => {},
  disabled = false,
  sameBackgroundColor = false,
  dataQa = "feature"
}) => {

  const toggle = () => {
    if (disabled) {
      return;
    }
    onToggle(!toggled)
  }

  return (
    <div
      onClick={toggle}
      style={{
        width: '32px',
        height: '19px',
        borderRadius: '20px',
        padding: '3px',
        background: disabled
          ? "bg-grey"
          : `linear-gradient(to right, var(--color-4) 50%, var(--color-4${sameBackgroundColor ? "" : "--40"}) 50%)`,
        backgroundSize: '200% 110%',
        backgroundPosition: toggled ? 'left bottom' : 'right bottom',
        transition: '.3s ease-in-out',
        cursor: disabled ? "default" : "pointer"
      }}
      data-qa={`${dataQa}-toggle`}
    >
      <div
        className={`bg-white`}
        style={{
          width: '13px',
          height: '13px',
          borderRadius: '50%',
          transform: toggled ? 'translateX(14px)' : '',
          transition: '.3s ease-in-out',
        }}
        data-qa={`${dataQa}-${toggled ? "on" : "off"}`}
      ></div>
    </div>
  );
};

export default Toggle;
