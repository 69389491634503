import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  generatePath,
  Link,
  matchPath,
  useLocation
} from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import SidebarLink from './SidebarLink';
import { capitalizeString } from 'Utils/utils';
import Urls from 'Utils/urls';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import infoNotification from 'Helpers/infoNotification';
import { getAccountRedirectPath } from 'Hooks/usePostLoginRedirect';
import { Arrow, LogoutSymbol } from 'Components/shared/symbols';
import Text from "Components/shared/Text";
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { UiContext } from 'States/ui/uiState';
import { KycContext } from 'States/kyc/kycState';
import { LangContext } from 'States/lang/langState';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { Button } from 'Components/shared/buttons';
import { AndroidIcon, IOSIcon } from 'Components/shared/symbols'
import LanguageSwitcher from 'Components/Sidebar/LanguageSwitcher';
import { STORAGE_KEYS, TENANT_FEATURE } from 'Enums';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import useConfigSettings from 'Hooks/useConfigSettings';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { ROUTES } from 'Router/Routes';
import GoldPrices from 'Features/Custom/Prices';

const isActiveRoute = (pathName, route, exact = false) =>
  matchPath(pathName, { path: route, exact });

const INFO_PAGES = ["how", "advantages", "faq"];

const Sidebar = ({
  features,
  restrictedMode,
  allowedRedemption = null,
  featuredAsset = null
}) => {
  const [accountRoute, setAccountRoute] = useState(ROUTES.account);
  const [showKycReminder, setShowKycReminder] = useState(false);
  const [showActivationToast, setShowActivationToast] = useState(false);
  const {
    isActive,
    isAdmin,
    isAuthenticated,
    isBlocked,
    isEmailConfirmed,
    user,
    status,
    getStatus,
    checkEmailConfirmation
  } = useContext(AuthContext);
  const {
    smallScreenSidebarShowing,
    setSmallScreenSidebarShowing,
  } = useContext(UiContext);
  const {
    config: {
      checkoutSettings,
      kycTiersDescription,
      kycTierFunctionalRestrictions,
      logo,
      logoUrl,
      androidUrl,
      iosUrl,
      redeemSettings,
      customerSpecific
    }
  } = useContext(ConfigContext);
  const {
    isKycEnabled,
    getUserKycData,
    isKycCaseLoading
  } = useContext(KycContext);
  const { lang } = useContext(LangContext);
  const { pathname } = useLocation();
  const { logEvent } = useAmplitude();

  const { data: defaultLanguageCode } = useConfigSettings.query({
    select: useCallback(({ Languages }) => {
      const language = Languages.find(({ IsDefault }) => IsDefault)
      return language?.Code
    }, [])
  });

  const { isUserTierSufficient: redeemVisibilityReached } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.redemption
  );
  const { isUserTierSufficient: purchaseVisibilityReached } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );

  const isFeaturedAssetEnabled = !!featuredAsset &&
    (!isAuthenticated || (isAdmin || purchaseVisibilityReached));

  const showAccountReminder = isAuthenticated && !isActive && !isBlocked &&
    !isActiveRoute(
      pathname,
      [ROUTES.signup, ROUTES.confirmEmail, ROUTES.verifyProfile],
      true
    );

  useEffect(() => {
    const loadLatestKycCase = async () => {
      const response = await getUserKycData();
      if (!response?.LatestCase) {
        setShowKycReminder(true);
      }
    }
    if (isActive && !isAdmin && isKycEnabled && !isKycCaseLoading) {
      loadLatestKycCase();
    } else {
      setShowKycReminder(false);
    }
  }, [isKycEnabled, isActive, isAdmin, pathname]);

  useEffect(() => {
    if (!isAuthenticated || isActiveRoute(pathname, ROUTES.logout, true)) {
      return;
    }
    getStatus(true);
    checkEmailConfirmation(true);
  }, [isAuthenticated, pathname]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const route = getAccountRedirectPath(status, isEmailConfirmed) || ROUTES.account;
    if (accountRoute !== route) {
      setAccountRoute(route);
    }
  }, [isAuthenticated, status, isEmailConfirmed]);

  useEffect(() => {
    if (!isActive && !isBlocked) {
      setShowActivationToast(true);
    }
  }, [isActive, isBlocked]);

  useEffect(() => {
    if (isActive && showActivationToast) {
      infoNotification(
        i18nextTranslate(i18nextKeys.signupNotificationSuccess),
        "accountActivation"
      );
      setShowActivationToast(false);
    }
  }, [isActive, showActivationToast]);

  const getConfigTranslation = (translations) => {
    if (!translations) {
      return;
    }
    return translations[lang] || translations[defaultLanguageCode] ||
      translations["en"] || Object.values(translations).find(translation => !!translation);
  };

  const hideSidebar = () => setSmallScreenSidebarShowing(false);

  const trackLogout = () => logEvent('Logout', {
    screen: location.pathname.split("/")[1] || "home"
  });

  const openUrl = (url) => window.open(url, "_blank", "noopener,noreferrer");

  const infoPageLinks = INFO_PAGES.map((name) => {
    const route = generatePath(ROUTES.custom.info, { name });
    return {
      route,
      text: i18nextTranslate(i18nextKeys[`customInfo${capitalizeString(name)}`]),
      alwaysShow: true,
      dataQa: `sidebar-info-${name}`,
      active: isActiveRoute(pathname, route, true)
    };
  });

  const links = [
    {
      route: ROUTES.login,
      text: i18nextTranslate(i18nextKeys.sidebarLoginRegister),
      requiresAuth: false,
      enabled: true,
      dataQa: 'sidebar-login',
      active: false,
      state: {
        [STORAGE_KEYS.authReturnPath]: pathname
      },
      onClick: () => logEvent(
        "Auth login started",
        { origin: "menu" }
      )
    },
    {
      route: restrictedMode && featuredAsset
        ? generatePath(
            ROUTES.featuredAsset,
            { id: featuredAsset.Id }
          )
        : ROUTES.featuredAssetBase,
      text: featuredAsset?.CustomPurchaseSettings?.SidebarLink,
      requiresAuth: false,
      enabled: isFeaturedAssetEnabled,
      dataQa: 'sidebar-customPurchase',
      active: isActiveRoute(
        pathname,
        [
          ROUTES.base,
          ROUTES.featuredAssetBase,
          ROUTES.featuredAsset
        ],
        true
      )
    },
    {
      route: !isAdmin && restrictedMode && featuredAsset
        ? generatePath(
            ROUTES.featuredAsset,
            { id: featuredAsset.Id }
          )
        : ROUTES.featuredAssetBase,
      text: featuredAsset?.CustomPurchaseSettings?.SidebarLink,
      requiresAuth: true,
      enabled: isFeaturedAssetEnabled,
      dataQa: 'sidebar-customPurchase',
      active: isActiveRoute(
        pathname,
        [
          ROUTES.base,
          ROUTES.featuredAssetBase,
          ROUTES.featuredAsset
        ],
        true
      )
    },
    ...infoPageLinks,
    {
      route: ROUTES.shop,
      text: i18nextTranslate(i18nextKeys.sidebarShop),
      requiresAuth: true,
      enabled:
        (isAdmin || (!restrictedMode && !checkoutSettings.hideShop)) &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.purchase) &&
        purchaseVisibilityReached,
      dataQa: 'sidebar-purchase',
      active: isActiveRoute(pathname, ROUTES.shop),
    },
    {
      route: ROUTES.shop,
      text: i18nextTranslate(i18nextKeys.sidebarShop),
      requiresAuth: false,
      enabled:
        !restrictedMode && !checkoutSettings.hideShop &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.purchase),
      dataQa: 'sidebar-purchase-view-only',
      active: isActiveRoute(pathname, ROUTES.shop),
    },
    {
      route: !isAdmin && restrictedMode && allowedRedemption
        ? generatePath(
            ROUTES.redemptionForm,
            { id: allowedRedemption }
          )
        : ROUTES.redemption,
      text: getConfigTranslation(redeemSettings?.menuItemName)
        || i18nextTranslate(i18nextKeys.sidebarRedemption),
      requiresAuth: true,
      enabled:
        (isAdmin || !restrictedMode || allowedRedemption) &&
        isTenantFeatureAvailable(features, TENANT_FEATURE.redeem) &&
        redeemVisibilityReached,
      dataQa: 'sidebar-redeem',
      active: isActiveRoute(pathname, ROUTES.redemption),
    },
    {
      route: ROUTES.admin.kyc,
      text: i18nextTranslate(i18nextKeys.sidebarKycCases),
      requiresAuth: true,
      adminOnly: true,
      enabled: isTenantFeatureAvailable(features, TENANT_FEATURE.kyc),
      dataQa: 'sidebar-admin-kyc',
      active: isActiveRoute(pathname, ROUTES.admin.kyc),
    },
    {
      route: ROUTES.admin.orders,
      text: i18nextTranslate(i18nextKeys.sidebarOrderManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-purchase',
      active: isActiveRoute(pathname, ROUTES.admin.orders),
    },
    {
      route: ROUTES.admin.redemptions,
      text: i18nextTranslate(i18nextKeys.sidebarRedemptionManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: isTenantFeatureAvailable(features, TENANT_FEATURE.redeem),
      dataQa: 'sidebar-admin-redeem',
      active: isActiveRoute(pathname, ROUTES.admin.redemptions),
    },
    {
      route: ROUTES.admin.config.tenant,
      text: i18nextTranslate(i18nextKeys.sidebarConfigManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-config',
      active: isActiveRoute(pathname, ROUTES.admin.config.tenant),
    },
    {
      route: ROUTES.admin.config.json,
      text: i18nextTranslate(i18nextKeys.sidebarJsonConfigManagement),
      requiresAuth: true,
      adminOnly: true,
      enabled: true,
      dataQa: 'sidebar-admin-json-config',
      active: isActiveRoute(pathname, ROUTES.admin.config.json),
    },
  ];

  const customerLogo = (
    <img
      data-qa="sidebar-logo"
      className="color-7"
      style={{
        maxWidth: '150px',
        maxHeight: '60px'
      }}
      src={`${Urls.get()?.blob}/${logo}`}
      alt="Company Logo"
    />
  );

  return (
    <div
      data-qa="sidebar"
      className={`flex-col ${smallScreenSidebarShowing ? 'flex' : 'hidden'}
        text-xl md:text-xs
        bg-1
        z-50 fixed lg:relative lg:flex lg:h-auto h-full`}
      style={{ minWidth: '282px', width: '282px' }}
    >
      <div className="w-full h-screen sticky top-0 overflow-auto flex flex-col">
        <div className="flex justify-between items-center mt-32 ml-32 mr-24 mb-24">
          {logoUrl ? (
            <a href={logoUrl} target="_blank" rel="noopener noreferrer">
              {customerLogo}
            </a>
          ) : customerLogo}
          <LanguageSwitcher />
        </div>
        <nav className="self-end flex flex-col justify-end w-full mb-32">
          {isAuthenticated && (
            <div
              className={`flex-none flex items-center justify-between px-16 py-8 ml-16 color-7 leading-5 rounded-l-lg ${isActiveRoute(pathname, 'account')
                ? 'sidebarLink-active-highlight'
                : ''
                }`}
              data-qa="sidebar-account"
            >
              <Link
                to={accountRoute}
                data-qa="sidebar-account-link"
                className="w-5/6 overflow-hidden"
              >
                <div className="flex flex-wrap">
                  <Text textStyle="p2" color="color-7">
                    {`${user.profile.given_name} ${user.profile.family_name}`}
                  </Text>
                </div>
                <div
                  data-qa="sidebar-account-email"
                >
                  <Text textStyle="p4" color="color-7">
                    {user.profile.email}
                  </Text>
                </div>
              </Link>
              <Link
                to={ROUTES.logout}
                data-qa="sidebar-logout"
              >
                <LogoutSymbol onClick={trackLogout} />
              </Link>
            </div>
          )}
          {showKycReminder && (
            <Link
              to={ROUTES.kyc}
              target="_blank"
              onClick={() => logEvent("KYC started", {
                origin: "reminder",
                provider: kycTiersDescription[1]?.providers[0].name !== KYC_PROVIDER.manual
              })}
              className="flex items-center gap-12 py-12 px-16 m-16 rounded-lg normal-case text-xs color-white bg-4--20"
              data-qa="sidebar-kycReminder"
            >
              <span className="w-5/6">
                {i18nextTranslate(i18nextKeys.sidebarKycReminder)}
              </span>
              <Arrow size="20" />
            </Link>
          )}
          {showAccountReminder && (
            <Link
              to={accountRoute}
              className="flex items-center gap-12 py-12 px-16 mx-16 my-12 rounded-lg bg-4--20"
              data-qa="sidebar-accountReminder"
            >
              <Text
                textStyle="text-small"
                color="color-white"
              >
                {i18nextTranslate(i18nextKeys.signupNotificationSidebar)}
              </Text>
            </Link>
          )}
          <ul className="subpixel-antialiased color-7 uppercase font-semibold w-full pl-16">
            {links.map(
              ({
                route,
                text,
                requiresAuth,
                enabled,
                adminOnly,
                dataQa,
                active,
                state,
                alwaysShow = false,
                onClick = () => { },
              }) => {
                // Route (or rather the link to it) is protected and requires a user to be logged in (authenticated)
                if (requiresAuth && enabled) {
                  if (
                    (isAuthenticated && !adminOnly) ||
                    (isAuthenticated && adminOnly && isAdmin)
                  ) {
                    return (
                      <SidebarLink
                        route={route}
                        text={text}
                        key={route + text}
                        dataQa={dataQa}
                        state={state}
                        onClick={() => {
                          onClick();
                          hideSidebar();
                        }}
                        active={active}
                      />
                    );
                  } else {
                    return null;
                  }
                }

                // Route is not protected and shall be shown to un-authenticated users only
                if ((!isAuthenticated && enabled) || alwaysShow) {
                  return (
                    <SidebarLink
                      route={route}
                      text={text}
                      key={route + text}
                      dataQa={dataQa}
                      state={state}
                      onClick={() => {
                        onClick();
                        hideSidebar();
                      }}
                      active={active}
                    />
                  );
                }

                // none of the above apply
                return null;
              }
            )}
          </ul>
        </nav>
        {customerSpecific?.DigitalFineGold.displayPrices && (
          <GoldPrices />
        )}
        <div className="mt-auto m-32 flex flex-col gap-16">
          {androidUrl ? (
            <Button
              onClick={() => openUrl(androidUrl)}
              icon={AndroidIcon}
              iconSize="22px"
              text={i18nextTranslate(i18nextKeys.sidebarAndroid)}
              className="flex-row-reverse"
              secondary
            />
          ) : null}
          {iosUrl ? (
            <Button
              onClick={() => openUrl(iosUrl)}
              icon={IOSIcon}
              iconSize="22px"
              text={i18nextTranslate(i18nextKeys.sidebarIOS)}
              className="flex-row-reverse"
              secondary
            />
          ) : null}
          <Link
            to={ROUTES.imprint}
            data-qa="sidebar-imprint"
          >
            <Text
              textStyle="h3"
              color="color-7"
            >
              {i18nextTranslate(i18nextKeys.sidebarImprint)}
            </Text>
          </Link>
          {isAuthenticated && (
            <Link
              to={ROUTES.about}
              data-qa="sidebar-about"
            >
              <Text
                textStyle="h3"
                color="color-7"
              >
                {`${i18nextTranslate(i18nextKeys.about)} (${
                  i18nextTranslate(i18nextKeys.commonVersion)
                } ${process.env.REACT_APP_VERSION.split("-")[0]})`}
              </Text>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
